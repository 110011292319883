import { Link } from "react-router-dom";
import React from "react";

const Search = ({ data, item, active }) => {
  const [search, setSearch] = React.useState("");
  const [filteredData, setFilteredData] = React.useState(data);
  // const [active, setActive] = React.useState(-1);
  React.useEffect(() => {
    setFilteredData(data);
  }, [data]);

  return (
    <div className="md:min-w-[15rem]">
      <input
        className="border-2 border-black  py-1 px-2 w-full md:w-auto"
        type="text"
        placeholder="Search"
        onChange={(e) => {
          setSearch(e.target.value);
          setFilteredData(
            data?.filter((item) =>
              item?.name.toLowerCase().includes(e.target.value.toLowerCase())
            )
          );
        }}
      />
      <div className="flex gap-1 mt-3   flex-col items-start ">
        {filteredData?.map((item2, index) => (
          <Link
            to={!item && "/admin/customer/:id"}
            onClick={() => {
              item && item(item2);
              // setActive(index);
            }}
            className={` py-2 px-3  flex-1 w-full hover:bg-gray-50
            ${item2?._id === active ? "bg-secondary border-2 border-black" : ""}
            `}
            key={index}
          >
            {item2?.name}
          </Link>
        ))}
      </div>
    </div>
  );
};

export default Search;

import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  deleteOrder,
  deletePayment,
  deletePurchaseOrder,
  updateOrder,
  updatePayment,
  updatePurchaseOrder,
} from "../utils/api";
import { toast } from "react-toastify";
import Loading from "./Loading";
import { IoIosClose } from "react-icons/io";
import CustomModal, { ModalInput, ModalSelect } from "./Modal";
import CustomSelect from "./Select";
import { formatDate } from "../utils/formatData";
import DeleteModal from "./modals/DeleteModal";

const ClientTable = ({
  client,
  data,
  id,
  customer,
  allid,
  all,
  reload,
  customers,
  reload2,
  adminClient,
  isCustomerSection,
}) => {
  const navigate = useNavigate();
  const [edit, setEdit] = useState(false);
  const [data2, setData2] = React.useState({
    date: new Date(),
  });
  const [loading, setLoading] = React.useState(false);
  return (
    <div className="w-full overflow-x-auto ">
      {loading && <Loading />}
      <table className="table-auto border-separate border-spacing-4 ">
        <thead>
          <tr>
            <th className="border whitespace-nowrap border-black px-2 py-1 bg-yellow-400">
              Date
            </th>
            <th className="border whitespace-nowrap border-black px-2 py-1 bg-yellow-400">
              Customer
            </th>
            <th className="border whitespace-nowrap border-black px-2 py-1 bg-yellow-400">
              Description
            </th>
            <th className="border whitespace-nowrap border-black px-2 py-1 bg-yellow-400">
              Location
            </th>
            <th className="border whitespace-nowrap border-black px-2 py-1 bg-yellow-400">
              Product
            </th>
            <th className="border whitespace-nowrap border-black px-2 py-1 bg-yellow-400">
              Qnt.
            </th>
            <th className="border whitespace-nowrap border-black px-2 py-1 bg-yellow-400">
              Rate
            </th>
            <th className="border whitespace-nowrap border-black px-2 py-1 bg-yellow-400">
              GST
            </th>
            <th className="border whitespace-nowrap border-black px-2 py-1 bg-yellow-400">
              Total
            </th>
            <th className="border whitespace-nowrap border-black px-2 py-1 bg-yellow-400">
              User
            </th>
            <th className="border whitespace-nowrap border-black px-2 py-1 bg-yellow-400">
              Image
            </th>
            <th className="  border whitespace-nowrap border-black px-2 py-1 bg-red-500">
              <Link
                to={
                  all
                    ? client
                      ? `/admin/pdf/client/all/${allid}`
                      : `/pdf/customer/all`
                    : client
                    ? `/admin/pdf/client/${id}`
                    : `/pdf/customer/${id}`
                }
                replace
                target="_blank"
              >
                PDF
              </Link>
            </th>
          </tr>
        </thead>
        <tbody>
          {data?.map((item, index) => (
            <tr key={index}>
              <td
                className={`
                ${
                  item?.paymentType
                    ? "bg-green-600"
                    : item?.type === "PurchaseOrder"
                    ? "bg-teal-400"
                    : ""
                }
                px-2 py-1 whitespace-nowrap border border-black`}
              >
                {item?.paymentType || item?.type === "PurchaseOrder"
                  ? formatDate(item?.date)
                  : formatDate(item?.completedDate)}
              </td>

              <td
                className={`      ${
                  item?.paymentType
                    ? "bg-green-600"
                    : item?.type === "PurchaseOrder"
                    ? "bg-teal-400"
                    : ""
                } px-2 py-1 whitespace-nowrap border border-black`}
              >
                {item.customer.userName}
              </td>
              <td
                className={`
                   ${
                     item?.paymentType
                       ? "bg-green-600"
                       : item?.type === "PurchaseOrder"
                       ? "bg-teal-400"
                       : ""
                   } px-2 py-1 whitespace-nowrap border border-black`}
              >
                {item.description}
              </td>
              <td
                className={`
                  ${
                    item?.paymentType
                      ? "bg-green-600"
                      : item?.type === "PurchaseOrder"
                      ? "bg-teal-400"
                      : ""
                  } px-2 py-1 whitespace-nowrap border border-black`}
              >
                {item?.customer?.group?.name}
              </td>

              {item?.paymentType ? (
                <>
                  <td
                    className={`     ${
                      item?.paymentType
                        ? "bg-green-600"
                        : item?.type === "PurchaseOrder"
                        ? "bg-teal-400"
                        : ""
                    } px-2 py-1 whitespace-nowrap border border-black`}
                    colSpan={2}
                  >
                    {item.paymentType}
                  </td>
                </>
              ) : (
                <>
                  <td
                    className={` ${
                      item?.paymentType
                        ? "bg-green-600"
                        : item?.type === "PurchaseOrder"
                        ? "bg-teal-400"
                        : ""
                    } px-2 py-1 whitespace-nowrap border border-black`}
                  >
                    {item?.product?.productName}
                  </td>
                  <td
                    className={`
                   ${
                     item?.paymentType
                       ? "bg-green-600"
                       : item?.type === "PurchaseOrder"
                       ? "bg-teal-400"
                       : ""
                   }
                  px-2 py-1 whitespace-nowrap border border-black`}
                  >
                    {item.quantity}
                  </td>
                </>
              )}

              {item?.paymentType ? (
                <>
                  <td
                    colSpan={3}
                    className={`
                    ${
                      item?.paymentType
                        ? "bg-green-600"
                        : item?.type === "PurchaseOrder"
                        ? "bg-teal-400"
                        : ""
                    }
                  px-2 py-1 whitespace-nowrap border border-black`}
                  >
                    {item.amount?.toFixed(2)}
                  </td>
                </>
              ) : (
                <>
                  <td
                    className={`
                   ${
                     item?.paymentType
                       ? "bg-green-600"
                       : item?.type === "PurchaseOrder"
                       ? "bg-teal-400"
                       : ""
                   }
                  
                  px-2 py-1 whitespace-nowrap border border-black`}
                  >
                    {item.rate}
                  </td>

                  <td
                    className={`px-2 py-1 whitespace-nowrap border border-black
                   ${
                     item?.paymentType
                       ? "bg-green-600"
                       : item?.type === "PurchaseOrder"
                       ? "bg-teal-400"
                       : ""
                   }
                  
                  `}
                  >
                    {item.gst}
                  </td>
                  <td
                    className={`px-2 py-1 whitespace-nowrap border border-black
                   ${
                     item?.paymentType
                       ? "bg-green-600"
                       : item?.type === "PurchaseOrder"
                       ? "bg-teal-400"
                       : ""
                   }
                  `}
                  >
                    {item.totalAmount?.toFixed(2)}
                  </td>
                </>
              )}
              <td
                className={`
                   ${
                     item?.paymentType
                       ? "bg-green-600"
                       : item?.type === "PurchaseOrder"
                       ? "bg-teal-400"
                       : ""
                   } px-2 py-1 whitespace-nowrap border border-black`}
              >
                {item?.createdByName}, {item?.completedByName}
              </td>
              <td
                className={`
                   ${
                     item?.paymentType
                       ? "bg-green-600"
                       : item?.type === "PurchaseOrder"
                       ? "bg-teal-400"
                       : ""
                   } px-2 py-1 whitespace-nowrap border border-black`}
              >
                <a
                  href={item?.customer?.image}
                  target="_blank"
                  rel="noreferrer"
                >
                  {item?.customer?.image ? "View" : ""}
                </a>
              </td>
              {!customer && (
                <td className="flex gap-2">
                  <button
                    onClick={() => {
                      setEdit(true);
                      setData2(item);
                    }}
                    className="focus:outline-none  px-2 py-1 whitespace-nowrap border bg-green-600 border-black"
                  >
                    Edit
                  </button>
                  <DeleteModal
                    onClick={async () => {
                      setLoading(true);

                      if (item?.paymentType) {
                        const [success, data] = await deletePayment(item._id);
                        if (success) {
                          toast.success("Payment Deleted");
                          reload();
                        } else {
                          toast.error("Payment Not Deleted");
                        }
                      } else if (item?.type === "PurchaseOrder") {
                        const [success, data] = await deletePurchaseOrder(
                          item._id
                        );
                        if (success) {
                          toast.success("Purchase Order Deleted");
                          reload();
                        } else {
                          toast.error("Purchase Order Not Deleted");
                        }
                      } else {
                        const [success, data] = await deleteOrder(item._id);
                        if (success) {
                          toast.success("Order Deleted");
                          reload();
                        } else {
                          toast.error(data);
                        }
                      }
                      setLoading(false);
                    }}
                  >
                    <IoIosClose />
                  </DeleteModal>
                </td>
              )}
            </tr>
          ))}
          {/* {data?.length === 0 && (
            <tr>
              <td
                className="border border-black px-2 py-1"
                colSpan={columns?.length}
              >
                No Data
              </td>
            </tr>
          )} */}
        </tbody>
      </table>
      <CustomModal
        open={edit}
        setOpen={() => {
          setEdit(false);
          setData2({});
        }}
        onClick={async () => {
          if (data2?.paymentType) {
            const [success, _] = await updatePayment(data2?._id, {
              ...data2,
              amount: parseFloat(data2.amount),
              date: data2.date,
            });
            if (success) {
              toast.success("Payment Updated");
              reload();
              // getCustomersByIdHandler(
              //   data.customerId,
              //   customers.find((item) => item._id === data.customerId).name
              // );
              setEdit(false);
              setData2({});
            }
            return;
          }

          if (data2?.type === "PurchaseOrder") {
            const [success, _] = await updatePurchaseOrder(data2?._id, {
              ...data2,
              quantity: parseInt(data2.quantity),
              rate: parseFloat(data2.rate),
              gst: parseInt(data2.gst),
            });
            if (success) {
              toast.success("Purchase Order Updated");
              reload();
              // getCustomersByIdHandler(
              //   data.customerId,
              //   customers.find((item) => item._id === data.customerId).name
              // );
              setEdit(false);
              setData2({});
            }
          } else {
            const [success, _] = await updateOrder(data2?._id, {
              ...data2,
              quantity: parseInt(data2.quantity),
              rate: parseFloat(data2.rate),
              gst: parseInt(data2.gst),
            });
            if (success) {
              toast.success("Order Updated");
              reload();
              // getCustomersByIdHandler(
              //   data.customerId,
              //   customers.find((item) => item._id === data.customerId).name
              // );
              setEdit(false);
              setData2({});
            }
          }
        }}
      >
        <div className="flex flex-col gap-3">
          <div className="flex justify-between gap-2">
            <div className="  border-black ">Customer</div>
            <CustomSelect
              width={"256px"}
              onChange={(e) => {
                setData2({
                  ...data2,
                  customerId: e.target.value,
                });
              }}
              options={customers
                ?.filter((item) => item?.type === "Customer")
                ?.map((customer) => ({
                  value: customer._id,
                  label: customer.name,
                }))}
              value={data2.customerId || data2?.customer?._id}
            />
          </div>
          {data2?.paymentType ? (
            <div className="flex justify-between gap-2">
              <div className=" border-black ">Payment Type</div>

              <CustomSelect
                width={"256px"}
                onChange={(e) => {
                  setData2({
                    ...data2,
                    paymentType: e.target.value,
                  });
                }}
                options={[
                  { value: "Received", label: "Received" },
                  { value: "Payment", label: "Payment" },
                ]}
                value={data2.paymentType}
              />
            </div>
          ) : (
            <>
              <ModalSelect
                title={"GST"}
                value={data2.gst}
                options={["0", "5", "12", "18"]}
                onChange={(e) => {
                  setData2({
                    ...data2,
                    gst: e.target.value,
                  });
                }}
              />
              <ModalInput
                title={"Quantity"}
                value={data2.quantity}
                onChange={(e) => {
                  setData2({
                    ...data2,
                    quantity: e.target.value,
                  });
                }}
              />
              <ModalInput
                title={"Rate"}
                value={data2.rate}
                onChange={(e) => {
                  setData2({
                    ...data2,
                    rate: e.target.value,
                  });
                }}
              />
            </>
          )}
          <ModalInput
            title={"description"}
            value={data2.description}
            onChange={(e) => {
              setData2({
                ...data2,
                description: e.target.value,
              });
            }}
          />
          <ModalInput
            title={"date"}
            type="date"
            value={
              typeof data2?.date === "string" && data2?.date?.split("T")?.[0]
            }
            onChange={(e) => {
              setData2({
                ...data2,
                date: e.target.value,
              });
            }}
          />
          {data2?.paymentType && (
            <ModalInput
              title={"amount"}
              value={data2.amount}
              type={"number"}
              onChange={(e) => {
                setData2({
                  ...data2,
                  amount: e.target.value,
                });
              }}
            />
          )}
        </div>
      </CustomModal>
    </div>
  );
};

export default ClientTable;

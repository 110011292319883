import React, { useState } from "react";
import { toast } from "react-toastify";
import CreateOrder from "../../../components/CreateOrder";
import Layout3 from "../../../components/Layout/Layout3";
import Loading from "../../../components/Loading";
import { ModalInput } from "../../../components/Modal";
import CustomSelect from "../../../components/Select";
import {
  createMarketingOrder,
  getAllCustomers,
  getGst,
  getProductData,
  getRate,
} from "../../../utils/api";
import useStore from "../../../utils/store";

const StaffMarketOrder = () => {
  const [customers, setCustomers] = useState([]);
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [newOrder, setNewOrder] = useState({
    customerId: "",
    productId: "",
    quantity: "",
    rate: "",
    description: "",
  });
  const getCustomers = async () => {
    setLoading(true);
    const [success, data] = await getAllCustomers();

    if (success) {
      setCustomers(data);
    }
    setLoading(false);
  };
  const user = useStore((state) => state.user);
  const getProductsHandler = async () => {
    setLoading(true);
    const [success, data] = await getProductData();
    if (success) {
      setProducts(data);
    }
    setLoading(false);
  };

  const getProuductRate = async (data) => {
    const [success, data2] = await getRate(data);
    if (success) {
      setNewOrder((prev) => ({
        ...prev,
        rate: data2.rate,
        ...data,
      }));
    } else {
      toast.error(data2);
    }
  };
  const getProuductgst = async (data) => {
    const [success, data2] = await getGst(data);
    if (success) {
      setNewOrder((prev) => ({
        ...prev,
        gst: data2.gst,
        ...data,
      }));
    } else {
      toast.error(data2);
    }
  };

  const addOrderHandler = async () => {
    const formdata = new FormData();
    formdata.append("customerId", newOrder.customerId);
    formdata.append("productId", newOrder.productId);
    formdata.append("quantity", +newOrder.quantity);
    formdata.append("rate", +newOrder.rate);
    formdata.append("staffId", user.staff._id);
    formdata.append("description", newOrder.description);
    formdata.append("gst", +newOrder.gst);
    // formdata.append("image", newOrder.image);
    if (
      newOrder.customerId === "" ||
      newOrder.productId === "" ||
      newOrder.quantity === "" ||
      newOrder.rate === ""
    ) {
      toast.error("Please fill all the fields");
      return;
    }
    setLoading(true);
    const [success, data] = await createMarketingOrder(formdata);

    if (success) {
      setNewOrder({
        customerId: "",
        productId: "",
        quantity: "",
        rate: "",
        description: "",
      });
      toast.success("Order added successfully");
      setLoading(false);
      return data;
    } else {
      toast.error(data);
      setLoading(false);
      return null;
    }
  };
  React.useEffect(() => {
    getCustomers();
    getProductsHandler();
  }, []);
  return (
    <Layout3 title={user?.staff?.userName}>
      {loading && <Loading />}
      <div className="flex md:items-center justify-center gap-3 md:flex-row flex-col bg-white py-10 px-10">
        <div className="flex flex-col gap-2">
          <div className="bg-secondary border-2 border-black px-2 py-1">
            Customer
          </div>
          <CustomSelect
            onChange={(e) => {
              if (newOrder?.productId) {
                getProuductRate({
                  customerId: e.target.value,
                  productId: newOrder?.productId,
                });
                getProuductgst({
                  customerId: e.target.value,
                  productId: newOrder?.productId,
                });
              } else {
                setNewOrder({ ...newOrder, customerId: e.target.value });
              }
            }}
            options={customers
              ?.filter((item) => item?.type === "Customer")
              ?.map((customer) => ({
                value: customer?._id,
                label: `${customer?.userName} (${customer?.group?.name})`,
              }))}
            value={newOrder?.customerId}
          />
        </div>
        <div className="flex flex-col gap-2">
          <div className="bg-secondary border-2 border-black px-2 py-1">
            description
          </div>
          <ModalInput
            width={"w-full"}
            value={newOrder?.description}
            onChange={(e) => {
              setNewOrder({ ...newOrder, description: e.target.value });
            }}
          />
        </div>
        <div className="flex flex-col gap-2">
          <div className="bg-secondary border-2 border-black px-2 py-1">
            Items
          </div>
          <CustomSelect
            onChange={(e) => {
              // setNewOrder({
              //   ...newOrder,
              //   productId: e.target.value,
              //   rate: products.find((item) => item._id === e.target.value)
              //     ?.rate,
              // });
              if (newOrder?.customerId) {
                getProuductRate({
                  customerId: newOrder?.customerId,
                  productId: e.target.value,
                });
                getProuductgst({
                  customerId: newOrder?.customerId,
                  productId: e.target.value,
                });
              } else {
                setNewOrder({ ...newOrder, productId: e.target.value });
              }
            }}
            options={products
              .filter((item) => item?.productType === "Sale")
              .map((item) => ({
                value: item?._id,
                label: item?.productName,
              }))}
            value={newOrder?.productId}
          />
        </div>
        <div className="flex flex-col gap-2">
          <div className="bg-secondary border-2 border-black px-2 py-1">
            Qnt
          </div>
          <ModalInput
            type={"number"}
            width={"w-full"}
            onChange={(e) => {
              setNewOrder({ ...newOrder, quantity: e.target.value });
            }}
            value={newOrder?.quantity}
          />
        </div>
        <div className="flex flex-col gap-2">
          <div className="bg-secondary border-2 border-black px-2 py-1">
            Rate
          </div>
          <ModalInput
            type={"number"}
            width={"w-full"}
            onChange={(e) => {
              setNewOrder({ ...newOrder, rate: e.target.value });
            }}
            value={newOrder?.rate}
          />
        </div>
        <div className="flex flex-col gap-2">
          <div className="bg-secondary border-2 border-black px-2 py-1">
            Gst
          </div>
          <ModalInput
            type={"number"}
            width={"w-full"}
            onChange={(e) => {
              setNewOrder({ ...newOrder, gst: e.target.value });
            }}
            value={newOrder?.gst}
          />
        </div>

        {/* <button
          onClick={addOrderHandler}
          className="border-2 w-full md:w-auto border-black bg-primary text-white py-1 px-2 focus:outline-none"
        >
          Save
        </button> */}
        <CreateOrder
          loading2={loading}
          onClick={addOrderHandler}
          id={newOrder?.customerId}
          qnt={newOrder?.quantity}
        />
      </div>
    </Layout3>
  );
};

export default StaffMarketOrder;

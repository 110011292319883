import {
  Document,
  PDFViewer,
  Page,
  StyleSheet,
  Text,
  View,
} from "@react-pdf/renderer";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Loading from "../../components/Loading";
import { orderDispatchAdmin } from "../../utils/api";
import useStore from "../../utils/store";

const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    backgroundColor: "#FFFFFF",
    padding: 25,
  },
  row: {
    flexDirection: "row",
    backgroundColor: "#E4E4E4",
    borderLeft: "1px solid #333",
  },
  row3: {
    flexDirection: "row",
    backgroundColor: "#E4E4E4",
    borderLeft: "1px solid #333",
    borderTop: "1px solid #333",
    marginTop: 10,
    marginBottom: 10,
  },
  row2: {
    flexDirection: "row",
    backgroundColor: "white",
    borderLeft: "1px solid #333",
  },
  headingTab: {
    flex: 1,
    fontSize: 7.4,
    textAlign: "center",
    borderRight: "1px solid #333",
    borderTop: "1px solid #333",
    borderBottom: "1px solid #333",
    paddingVertical: 5,
    paddingHorizontal: 5,
    backgroundColor: "#fff101",
    fontWeight: "bold",
  },
  headingTabGreen: {
    flex: 1,
    fontSize: 7.4,
    textAlign: "center",
    borderRight: "1px solid #333",
    borderTop: "1px solid #333",
    borderBottom: "1px solid #333",
    paddingVertical: 5,
    paddingHorizontal: 5,
    backgroundColor: "#1EB14B",
    fontWeight: "bold",
    marginTop: 10,
  },
  headingGstTab: {
    flex: 0.4,
    fontSize: 7.4,
    textAlign: "center",
    borderRight: "1px solid #333",
    borderTop: "1px solid #333",
    borderBottom: "1px solid #333",
    paddingVertical: 5,
    paddingHorizontal: 5,
    backgroundColor: "#fff101",
    fontWeight: "bold",
  },
  headingRateTab: {
    flex: 0.6,
    fontSize: 7.4,
    textAlign: "center",
    borderRight: "1px solid #333",
    borderTop: "1px solid #333",
    borderBottom: "1px solid #333",
    paddingVertical: 5,
    paddingHorizontal: 5,
    backgroundColor: "#fff101",
    fontWeight: "bold",
  },
  headingDateTab: {
    flex: 0.8,
    fontSize: 7.4,
    textAlign: "center",
    borderRight: "1px solid #333",
    borderTop: "1px solid #333",
    borderBottom: "1px solid #333",
    paddingVertical: 5,
    paddingHorizontal: 5,
    backgroundColor: "#fff101",
    fontWeight: "bold",
  },
  headingQntTab: {
    flex: 0.7,
    fontSize: 7.4,
    textAlign: "center",
    borderRight: "1px solid #333",
    borderTop: "1px solid #333",
    borderBottom: "1px solid #333",
    paddingVertical: 5,
    paddingHorizontal: 5,
    backgroundColor: "#fff101",
    fontWeight: "bold",
  },
  headingCustomerTab: {
    flex: 0.7,
    fontSize: 7.4,
    textAlign: "center",
    borderRight: "1px solid #333",
    borderTop: "1px solid #333",
    borderBottom: "1px solid #333",
    paddingVertical: 5,
    paddingHorizontal: 5,
    backgroundColor: "#fff101",
    fontWeight: "bold",
  },
  headingTotalTab: {
    flex: 0.7,
    fontSize: 7.4,
    textAlign: "center",
    borderRight: "1px solid #333",
    borderTop: "1px solid #333",
    borderBottom: "1px solid #333",
    paddingVertical: 5,
    paddingHorizontal: 5,
    backgroundColor: "#fff101",
    fontWeight: "bold",
  },
  headingTab2: {
    flex: 1,
    fontSize: 7.4,
    textAlign: "end",
    borderRight: "1px solid #333",
    borderBottom: "1px solid #333",
    paddingVertical: 5,
    paddingHorizontal: 5,
    backgroundColor: "white",
    fontWeight: "bold",
  },
  headingTabNB: {
    flex: 1,
    fontSize: 7.4,
    textAlign: "end",
    borderRight: "1px solid #333",
    paddingVertical: 5,
    paddingHorizontal: 5,
    backgroundColor: "white",
    fontWeight: "bold",
  },
  headingTabBB: {
    flex: 1,
    fontSize: 7.4,
    textAlign: "end",
    borderRight: "1px solid #333",
    borderBottom: "1px solid #333",
    paddingVertical: 5,
    paddingHorizontal: 5,
    backgroundColor: "white",
    fontWeight: "bold",
  },
  headingTabTB: {
    flex: 1,
    fontSize: 7.4,
    textAlign: "end",
    borderRight: "1px solid #333",
    borderTop: "1px solid #333",
    paddingVertical: 5,
    paddingHorizontal: 5,
    backgroundColor: "white",
    fontWeight: "bold",
  },
  headingGstTab2: {
    flex: 0.4,
    fontSize: 7.4,
    textAlign: "end",
    borderRight: "1px solid #333",
    borderBottom: "1px solid #333",
    paddingVertical: 5,
    paddingHorizontal: 5,
    backgroundColor: "white",
    fontWeight: "bold",
  },
  headingRateTab2: {
    flex: 0.6,
    fontSize: 7.4,
    textAlign: "end",
    borderRight: "1px solid #333",
    borderBottom: "1px solid #333",
    paddingVertical: 5,
    paddingHorizontal: 5,
    backgroundColor: "white",
    fontWeight: "bold",
  },
  headingDateTab2: {
    flex: 0.8,
    fontSize: 7.4,
    textAlign: "end",
    borderRight: "1px solid #333",
    borderBottom: "1px solid #333",
    paddingVertical: 5,
    paddingHorizontal: 5,
    backgroundColor: "white",
    fontWeight: "bold",
  },
  headingQntTab2: {
    flex: 0.7,
    fontSize: 7.4,
    textAlign: "end",
    borderRight: "1px solid #333",
    borderBottom: "1px solid #333",
    paddingVertical: 5,
    paddingHorizontal: 5,
    backgroundColor: "white",
    fontWeight: "bold",
  },
  headingTotalTab2: {
    flex: 0.7,
    fontSize: 7.4,
    textAlign: "end",
    borderRight: "1px solid #333",
    borderBottom: "1px solid #333",
    paddingVertical: 5,
    paddingHorizontal: 5,
    backgroundColor: "white",
    fontWeight: "bold",
  },
  headingTab3: {
    fontSize: 7.4,
    textAlign: "end",
    borderRight: "1px solid #333",
    paddingVertical: 5,
    paddingHorizontal: 5,
    backgroundColor: "#fff101",
    fontWeight: "bold",
  },
  headingTabGreen: {
    flex: 1.4,
    fontSize: 7.4,
    borderRight: "1px solid #333",
    borderBottom: "1px solid #333",
    padding: 5,
    backgroundColor: "#1EB14B",
    fontWeight: "bold",
  },
  headingTabDateGreen: {
    flex: 1.15,
    fontSize: 7.4,
    borderRight: "1px solid #333",
    borderBottom: "1px solid #333",
    padding: 5,
    backgroundColor: "#1EB14B",
    fontWeight: "bold",
  },
  headingTabGreen2: {
    flex: 4.53,
    fontSize: 7.4,
    textAlign: "center",
    borderRight: "1px solid #333",
    borderBottom: "1px solid #333",
    padding: 5,
    backgroundColor: "#1EB14B",
    fontWeight: "bold",
  },
  headingTotalTabGreen: {
    flex: 0.99,
    fontSize: 7.4,
    borderRight: "1px solid #333",
    borderBottom: "1px solid #333",
    padding: 5,
    backgroundColor: "#1EB14B",
    fontWeight: "bold",
  },
  headingDateTab4: {
    flex: 0.8,
    fontSize: 7.4,
    textAlign: "end",
    paddingVertical: 5,
    paddingHorizontal: 5,
    backgroundColor: "#fff101",
    fontWeight: "bold",
  },
  headingTab4: {
    flex: 1,
    fontSize: 7.4,
    textAlign: "center",
    paddingVertical: 5,
    paddingHorizontal: 5,
    backgroundColor: "#fff101",
    fontWeight: "bold",
  },
  headingGstTab4: {
    flex: 0.4,
    fontSize: 7.4,
    textAlign: "center",
    borderRight: "1px solid #333",
    paddingVertical: 5,
    paddingHorizontal: 5,
    backgroundColor: "#fff101",
    fontWeight: "bold",
  },
  headingRateTab4: {
    flex: 0.6,
    fontSize: 7.4,
    textAlign: "center",
    paddingVertical: 5,
    paddingHorizontal: 5,
    backgroundColor: "#fff101",
    fontWeight: "bold",
  },
  headingQntTab4: {
    flex: 0.7,
    fontSize: 7.4,
    textAlign: "center",
    borderLeft: "1px solid #333",
    borderRight: "1px solid #333",
    paddingVertical: 5,
    paddingHorizontal: 5,
    backgroundColor: "#fff101",
    fontWeight: "bold",
  },
  headingCustomerTab4: {
    flex: 0.7,
    fontSize: 7.4,
    textAlign: "center",
    borderRight: "1px solid #333",
    borderTop: "1px solid #333",
    borderBottom: "1px solid #333",
    paddingVertical: 5,
    paddingHorizontal: 5,
    backgroundColor: "#fff101",
    fontWeight: "bold",
  },
  headingTotalTab4: {
    flex: 0.7,
    fontSize: 7.4,
    borderRight: "1px solid #333",
    paddingVertical: 5,
    paddingHorizontal: 5,
    backgroundColor: "#fff101",
    fontWeight: "bold",
  },
  section: {
    margin: 10,
    marginRight: 10,
    border: "1px solid #333",
    width: "100%",
    flexDirection: "row",
  },
  text: {
    flex: 1,
  },
});

const DispatchPdfComplete = () => {
  const [list, setList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const startDate = useStore((state) => state.startDate);
  const endDate = useStore((state) => state.endDate);
  const params = useParams();
  const getdata = async () => {
    const [sucesss, data] = await orderDispatchAdmin(startDate, endDate);
    if (sucesss) {
      setList(data);
    } else {
      toast.error("Something went wrong");
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getdata();
  }, [params.id]);

  return isLoading ? (
    <Loading />
  ) : (
    <PDFViewer
      className="
    "
      style={{ width: "100%", height: "100vh" }}
    >
      <Document>
        <Page size="A4" style={styles.page}>
          {list?.map((item, index) => (
            <View key={index}>
              <View style={styles.row3}>
                <Text style={styles.headingTabGreen}>Date {item?._id}</Text>
                {item?.tag > 0 && (
                  <Text style={styles.headingTabGreen}>Tag: {item?.tag}</Text>
                )}
                {item?.stancil > 0 && (
                  <Text style={styles.headingTabGreen}>
                    Stancil: {item?.stancil}
                  </Text>
                )}
                {item?.trading > 0 && (
                  <Text style={styles.headingTabGreen}>
                    Trading: {item?.trading}
                  </Text>
                )}
              </View>

              <View style={styles.row}>
                <Text style={styles.headingDateTab}>Date</Text>
                <Text style={styles.headingTab}>Customer</Text>
                <Text style={styles.headingTab}>City</Text>
                <Text style={styles.headingTab}>Location</Text>
                <Text style={styles.headingTab}>PhoneNumber</Text>

                <Text style={styles.headingTab}>Items</Text>
                <Text style={styles.headingQntTab}>Qnt.</Text>
              </View>
              {item?.data?.map((date, index) => (
                <View key={index}>
                  {date?.orders?.map((order, index) => {
                    const length = date?.orders?.length ?? 1;
                    return (
                      <View style={styles.row} key={index}>
                        <Text style={styles.headingDateTab2}>
                          {new Date(order?.updatedAt).toLocaleDateString() +
                            " " +
                            new Date(order?.updatedAt).toLocaleTimeString()}
                        </Text>
                        <Text style={styles.headingTab2}>
                          {order?.customer?.userName}
                        </Text>
                        <Text style={styles.headingTab2}>{order?.group}</Text>
                        <Text style={styles.headingTab2}>
                          {order?.customer?.location}
                        </Text>
                        {length === 1 && index === 0 ? (
                          <Text style={styles.headingTab2}>
                            {date?._id?.phoneNumber}
                          </Text>
                        ) : index === 0 ? (
                          <Text style={styles.headingTabTB}>
                            {" "}
                            {date?._id?.phoneNumber}
                          </Text>
                        ) : index === length - 1 ? (
                          <Text style={styles.headingTabBB}></Text>
                        ) : (
                          <Text style={styles.headingTabNB}></Text>
                        )}
                        <Text style={styles.headingTab2}>
                          {order?.product?.productName}
                        </Text>
                        <Text style={styles.headingQntTab2}>
                          {order?.quantity}
                        </Text>
                      </View>
                    );
                  })}
                </View>
              ))}
            </View>
          ))}
        </Page>
      </Document>
    </PDFViewer>
  );
};

export default DispatchPdfComplete;

import React from "react";
import { toast } from "react-toastify";
import Layout from "../../components/Layout/Layout";
import Loading from "../../components/Loading";
import CustomModal, { ModalInput } from "../../components/Modal";
import Search from "../../components/Search";
import Success from "../../components/Success";
import Table from "../../components/Table";
import {
  createCustomerGroup,
  deleteCustomerGroup,
  editLocation,
  getAllCustomerGroups,
  getCustomerGroupById,
} from "../../utils/api";
import useStore from "../../utils/store";

const Group = () => {
  const [open, setOpen] = React.useState(false);
  const [newGroup, setNewGroup] = React.useState("");
  const [groups, setGroups] = React.useState([]);
  const [selected, setSelected] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const [success, setSuccess] = React.useState(false);
  const startDate = useStore((state) => state.startDate);
  const endDate = useStore((state) => state.endDate);
  const createNewGroup = async () => {
    if (!newGroup) {
      alert("Please fill all the fields");
      return;
    }
    setLoading(true);
    const [success, data] = await createCustomerGroup({
      name: newGroup,
    });
    if (success) {
      setOpen(false);
      setNewGroup("");
      setSuccess(true);
      getAllCustomerGroupsHandler();
    } else {
      toast.error(data);
    }
    setLoading(false);
  };

  const getGroupbyId = async (id) => {
    setLoading(true);
    const [success, data] = await getCustomerGroupById(id);
    if (success) {
      setSelected(data);
    }
    setLoading(false);
  };

  const getAllCustomerGroupsHandler = async () => {
    setLoading(true);
    const [success, data] = await getAllCustomerGroups(startDate, endDate);
    if (success) {
      setGroups(data);
    }
    setLoading(false);
  };
  React.useEffect(() => {
    getAllCustomerGroupsHandler();
  }, [startDate, endDate]);

  return (
    <Layout
      text={"Add New Group"}
      onClick={() => {
        setOpen(true);
      }}
    >
      <div className="flex gap-3 flex-col md:flex-row">
        <Search
          data={groups}
          item={(item) => {
            getGroupbyId(item._id);
          }}
        />
        {selected && (
          <EditGroup
            item={selected}
            reload={getAllCustomerGroupsHandler}
            setItem={setSelected}
            groups={groups}
          />
        )}
      </div>
      {loading && <Loading />}
      <CustomModal
        open={open}
        setOpen={setOpen}
        onClick={createNewGroup}
        loading={loading}
      >
        <ModalInput
          title="Group Name"
          value={newGroup}
          onChange={(e) => {
            setNewGroup(e.target.value);
          }}
        />
      </CustomModal>
      {success && <Success open={success} setOpen={setSuccess} />}
    </Layout>
  );
};

export default Group;

const EditGroup = ({ item, reload, setItem, groups }) => {
  const [loading, setLoading] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [newGroup, setNewGroup] = React.useState({});

  const editGroup = async () => {
    if (!newGroup) {
      alert("Please fill all the fields");
      return;
    }
    setLoading(true);
    const [success, data] = await editLocation(newGroup?._id, {
      name: newGroup?.name,
    });
    if (success) {
      setOpen(false);
      setNewGroup("");
      setItem(null);
      reload();
    } else {
      alert(data);
    }
    setLoading(false);
  };
  return (
    <div className="flex flex-col gap-10">
      <CustomModal
        open={open}
        setOpen={setOpen}
        onClick={editGroup}
        loading={loading}
      >
        <ModalInput
          title="Group Name"
          value={newGroup?.name}
          onChange={(e) => {
            setNewGroup({
              ...newGroup,
              name: e.target.value,
            });
          }}
        />
      </CustomModal>
      <div className="flex gap-2 items-start ">
        <Table
          columns={[
            {
              title: "Name",
              dataIndex: "name",
              render: (item) => item?.customerGroup?.name,
            },
            {
              title: "Customer",
              dataIndex: "customerlength",
              render: (item) => item?.customerlength,
            },
            {
              title: "Action",
              render: (item) => (
                <>
                  <button
                    onClick={() => {
                      setOpen(true);
                      setNewGroup({
                        name: item?.customerGroup?.name,
                        _id: item?.customerGroup?._id,
                      });
                    }}
                    className="border border-black  py-1 bg-primary mr-2 px-4 text-xl "
                  >
                    {loading ? "Loading..." : "Edit"}
                  </button>
                  <button
                    onClick={async () => {
                      setLoading(true);
                      const [success, data] = await deleteCustomerGroup(
                        item?.customerGroup?._id
                      );
                      if (success) {
                        setItem(null);
                        reload();
                        toast.success("Deleted Successfully");
                      }
                      setLoading(false);
                    }}
                    className="border border-black  py-1 bg-red-600 px-4 text-xl "
                  >
                    {loading ? "Loading..." : "Delete"}
                  </button>
                </>
              ),
            },
          ]}
          data={[item]}
        />
      </div>
    </div>
  );
};

import React, { useState } from "react";
import CustomModal, { ModalInput, ModalSelect } from "../Modal";
import { createProduct, updateProduct } from "../../utils/api";
import Success from "../Success";
import { toast } from "react-toastify";

const ProductModal = ({ open, setOpen, reload, update, data, setProduct }) => {
  const [loading, setLoading] = useState(false);
  const [newProduct, setNewProduct] = useState({});
  const [success, setSuccess] = useState(false);
  const createCustomerHandler = async () => {
    if (
      !newProduct.productName ||
      !newProduct.productType ||
      !newProduct.tax ||
      !newProduct.group ||
      !newProduct.rate ||
      !newProduct.hsn
    ) {
      alert("Please fill all the fields");
      return;
    }

    setLoading(true);
    if (update) {
      const [success, data] = await updateProduct(newProduct?._id, newProduct);
      if (success) {
        setNewProduct({});
        setProduct((prev) => ({
          ...prev,
          product: newProduct,
        }));
        setSuccess(true);
        setOpen(false);
        toast.success("Product Updated Successfully");
      }
    } else {
      const [success, data] = await createProduct(newProduct);
      if (success) {
        setNewProduct({});
        reload();
        setSuccess(true);
        setOpen(false);
        toast.success("Product Created Successfully");
      }
    }
    setLoading(false);
  };

  React.useEffect(() => {
    if (update) {
      setNewProduct(data);
    }
  }, [update, data]);
  return (
    <>
      <CustomModal
        open={open}
        setOpen={setOpen}
        loading={loading}
        onClick={createCustomerHandler}
      >
        <ModalInput
          title="Product Name"
          value={newProduct?.productName}
          onChange={(e) => {
            setNewProduct({
              ...newProduct,
              productName: e.target.value,
            });
          }}
        />
        <ModalSelect
          title="GST(%)"
          options={["0", "5", "12", "18"]}
          value={newProduct?.tax}
          onChange={(e) => {
            setNewProduct({
              ...newProduct,
              tax: e.target.value,
            });
          }}
        />
        <ModalSelect
          title="TYPE"
          options={["Sale", "Purchase"]}
          value={newProduct?.productType}
          onChange={(e) => {
            setNewProduct({
              ...newProduct,
              productType: e.target.value,
            });
          }}
        />

        <ModalSelect
          title="Group"
          options={["Tag", "Stancil", "Trading", "Other"]}
          value={newProduct?.group}
          onChange={(e) => {
            setNewProduct({
              ...newProduct,
              group: e.target.value,
            });
          }}
        />
        <ModalInput
          title="Rate"
          type="number"
          value={newProduct?.rate}
          onChange={(e) => {
            setNewProduct({
              ...newProduct,
              rate: e.target.value,
            });
          }}
        />
        <ModalInput
          title="HSN"
          type="number"
          value={newProduct?.hsn}
          onChange={(e) => {
            setNewProduct({
              ...newProduct,
              hsn: e.target.value,
            });
          }}
        />
      </CustomModal>
      {success && <Success />}
    </>
  );
};

export default ProductModal;

import { Link } from "react-router-dom";
import React from "react";
import { searchCustomer, searchSuperStaff } from "../utils/api";
import { CircularProgress } from "@mui/material";

const ApiSearch = ({ data, item, active, customer }) => {
  const [search, setSearch] = React.useState("");
  const [filteredData, setFilteredData] = React.useState(data);
  // const [active, setActive] = React.useState(-1);
  const [loading, setLoading] = React.useState(false);
  const searchHandler = async (e) => {
    setSearch(e.target.value);
    // if (e.target.value === "") {
    //   setFilteredData(data);
    //   return;
    // }
    setLoading(true);
    const [success, data2] = customer
      ? await searchCustomer({ query: e.target.value })
      : await searchSuperStaff({ query: e.target.value });
    if (success) {
      setFilteredData(
        data2?.map((item) => ({
          ...item,
          userName: `${item.userName} ${
            item?.group?.name !== undefined ? `(${item?.group?.name})` : ""
          }`,
        }))
      );
    } else {
      setFilteredData([]);
    }
    setLoading(false);
  };

  React.useEffect(() => {
    setFilteredData(data);
  }, [data]);

  return (
    <div className="min-w-[18rem]">
      <div className="flex gap-3">
        <input
          className="border-2 border-black  py-1 px-2 w-full md:w-auto"
          type="text"
          placeholder="Search"
          onChange={searchHandler}
        />
        {loading && <CircularProgress />}
      </div>
      <div className="flex gap-1 mt-3   flex-col items-start ">
        {filteredData?.map((item2, index) => (
          <Link
            to={!item && "/admin/customer/:id"}
            onClick={() => {
              item && item(item2);
              // setActive(index);
            }}
            className={` py-2 px-3  flex-1 w-full hover:bg-gray-50
            ${item2?._id === active ? "bg-secondary border-2 border-black" : ""}
            `}
            key={index}
          >
            {customer ? item2?.userName : item2?.name}
          </Link>
        ))}
      </div>
    </div>
  );
};

export default ApiSearch;

import { useEffect, useState } from "react";
import Sidebar from "./Sidebar";
import Head from "../Head";
import useStore from "../../utils/store";
import { useNavigate } from "react-router-dom";

const Layout3 = ({ children, onClick, text, title }) => {
  const [open, setOpen] = useState(false);
  const user = useStore((state) => state.user);
  const navigate = useNavigate();
  useEffect(() => {
    if (!user || user?.type !== "MarketingStaff") {
      navigate("/login");
    }
  }, [user, navigate]);
  return (
    <div className="flex h-[100dvh] bg-layoutbg">
      <Sidebar
        open={open}
        setOpen={setOpen}
        links={[
          {
            title: "Dashboard",
            path: "/staff/marketing",
          },
          {
            title: "Orders",
            path: "/staff/marketing/orders",
          },
          {
            title: "Purchase",
            path: "/staff/marketing/purchase",
          },
          {
            title: "Payment",
            path: "/staff/marketing/payment",
          },
          {
            title: "Dispatch",
            path: "/staff/marketing/dispatch",
          },
          {
            title: "Invoices",
            path: "/staff/marketing/invoices",
          },
          {
            title: "Customer Add",
            path: "/staff/marketing/customer/add",
          },
          {
            title: "Logout",
            path: "/logout",
          },
        ]}
      />

      <div className="overflow-y-auto w-full ">
        <Head
          onClick={onClick}
          text={text}
          title={title}
          menu={() => setOpen(true)}
        />
        <div className="px-6 py-4">{children}</div>
      </div>
    </div>
  );
};

export default Layout3;

import React from "react";
import { toast } from "react-toastify";
import Layout3 from "../../../components/Layout/Layout3";
import Loading from "../../../components/Loading";
import DispatchTable from "../../../components/dispatchTable";
import { dispatchComplete, getAllDispatch } from "../../../utils/api";
import useStore from "../../../utils/store";

const DispatchMarket = () => {
  const [dash, setDash] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const getData = async () => {
    setLoading(true);
    const [success, data] = await getAllDispatch();
    if (success) {
      setDash(data);
    }
    setLoading(false);
  };
  const user = useStore((state) => state.user);

  React.useEffect(() => {
    getData();
  }, []);
  return (
    <Layout3 type={"Dispatch"} title={user?.staff?.userName}>
      {loading && <Loading />}
      <DispatchTable
        complete={async (ids) => {
          setLoading(true);
          const [success, data] = await dispatchComplete({
            type: "staff",
            completedById: user.staff._id,
            ids: ids,
          });
          if (success) {
            toast.success("Dispatch Completed");
            getData();
          } else {
            toast.error(data);
          }
          setLoading(false);
        }}
        type="dispatch"
        data={dash}
      />
    </Layout3>
  );
};

export default DispatchMarket;

import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import logo from "../../assets/logo.png";
import useStore from "../../utils/store";
import { Button } from "@mui/material";
import CustomModal, { ModalInput } from "../Modal";
import { useState } from "react";
import CustomSelect from "../Select";
import { toast } from "react-toastify";
import {
  createCustomerOrder,
  getGst,
  getPendingOrder,
  getRate,
} from "../../utils/api";
import CreateOrder from "../CreateOrder";

const Layout2 = ({ children, products, customers, onClick }) => {
  const user = useStore((state) => state.user);
  const navigate = useNavigate();
  const [pending, setPending] = useState(null);
  const setUser = useStore((state) => state.setUser);
  const [newOrder, setNewOrder] = useState({
    customerId: "",
    productId: "",
    quantity: "",
    rate: "",
    description: "",
  });

  const getPendingOrderHandler = async () => {
    const [success, data] = await getPendingOrder(user?.customer?._id);
    if (success) {
      setPending(data);
    }
  };
  const getProuductRate = async (data) => {
    const [success, data2] = await getRate(data);
    if (success) {
      setNewOrder((prev) => ({
        ...prev,
        rate: data2.rate,
        ...data,
      }));
    } else {
      toast.error(data2);
    }
  };
  const getProuductgst = async (data) => {
    const [success, data2] = await getGst(data);
    if (success) {
      setNewOrder((prev) => ({
        ...prev,
        gst: data2.gst,
        ...data,
      }));
    } else {
      toast.error(data2);
    }
  };
  const [loading, setLoading] = useState(false);
  const addOrderHandler = async () => {
    if (
      newOrder?.gst === null ||
      newOrder?.rate === null ||
      !newOrder?.quantity ||
      !newOrder?.productId ||
      !newOrder?.customerId
    ) {
      return toast.error("Something went wrong");
    }

    const formdata = new FormData();
    formdata.append("customerId", newOrder.customerId);
    formdata.append("productId", newOrder.productId);
    formdata.append("quantity", +newOrder.quantity);
    formdata.append("rate", +newOrder.rate);
    formdata.append("description", newOrder.description);
    formdata.append("gst", +newOrder.gst);
    // formdata.append("image", newOrder.image);

    setLoading(true);
    const [success, data] = await createCustomerOrder(user.token, formdata);
    if (success) {
      setNewOrder({
        customerId: "",
        productId: "",
        quantity: "",
        rate: "",
        description: "",
      });
      setOpen(false);
      toast.success("Order added successfully");
    } else {
      toast.error(data);
    }
    setLoading(false);
  };

  const [open, setOpen] = useState(false);
  useEffect(() => {
    if (!user || user?.type !== "Customer") {
      navigate("/login");
    } else {
      getPendingOrderHandler();
    }
  }, [user, navigate]);
  return (
    <div className="flex flex-col  h-[100dvh] bg-layoutbg">
      <div className="flex gap-2 justify-between items-center px-6 ">
        <div className="flex gap-6 items-center ">
          <img src={logo} alt="logo" className="w-72 h-44 px-6 py-6 " />
        </div>
        {/* logout */}
        <div className="flex gap-6 items-center ">
          <button
            onClick={onClick}
            className="px-2 py-2 bg-secondary  rounded font-medium border border-black"
          >
            Pending Orders: {pending?.pendingOrderCount}
          </button>
          {user?.customer?.orderAccess && (
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                setOpen(true);
              }}
            >
              Create Order
            </Button>
          )}
          <Button
            variant="contained"
            color="error"
            onClick={() => {
              setUser(null);
              navigate("/login");
            }}
          >
            Logout
          </Button>
        </div>
      </div>
      <div className="overflow-y-auto px-6 py-3">{children}</div>
      <CustomModal
        open={open}
        setOpen={setOpen}
        title="Create Order"
        loading={loading}
        button={
          <CreateOrder
            loading2={loading}
            onClick={addOrderHandler}
            id={newOrder?.customerId}
            qnt={newOrder?.quantity}
          />
        }
      >
        <div className=" flex-col flex gap-2">
          <div className="flex  gap-2 justify-between ">
            <div className=" px-2 py-1">Customer</div>
            <CustomSelect
              width={"16rem"}
              onChange={(e) => {
                // setNewOrder({ ...newOrder, customerId: e.target.value });
                if (newOrder?.productId) {
                  getProuductRate({
                    customerId: e.target.value,
                    productId: newOrder.productId,
                  });
                  getProuductgst({
                    customerId: e.target.value,
                    productId: newOrder.productId,
                  });
                } else {
                  setNewOrder({ ...newOrder, customerId: e.target.value });
                }
              }}
              options={customers
                ?.filter((item) => item?.type === "Customer")
                ?.map((customer) => ({
                  value: customer._id,
                  label: `${customer.userName}${
                    customer?.group?.name !== undefined
                      ? `(${customer?.group?.name})`
                      : ""
                  }`,
                }))}
              value={newOrder.customerId}
            />
          </div>
          <div className="flex  gap-2 justify-between ">
            <div className=" px-2 py-1">description</div>

            <ModalInput
              value={newOrder?.description}
              onChange={(e) => {
                setNewOrder({ ...newOrder, description: e.target.value });
              }}
            />
          </div>
          <div className="flex  gap-2 justify-between ">
            <div className=" px-2 py-1">Items</div>

            <CustomSelect
              width={"16rem"}
              onChange={(e) => {
                // setNewOrder({ ...newOrder, productId: e.target.value });
                if (newOrder?.customerId) {
                  getProuductRate({
                    customerId: newOrder.customerId,
                    productId: e.target.value,
                  });
                  getProuductgst({
                    customerId: newOrder.customerId,
                    productId: e.target.value,
                  });
                } else {
                  setNewOrder({ ...newOrder, productId: e.target.value });
                }
              }}
              options={products
                ?.filter((item) => item?.productType === "Sale")
                ?.map((item) => ({
                  value: item._id,
                  label: item.productName,
                }))}
              value={newOrder?.productId}
            />
          </div>
          <div className="flex  gap-2 justify-between ">
            <div className=" px-2 py-1">Qnt</div>

            <ModalInput
              type={"number"}
              onChange={(e) => {
                setNewOrder({ ...newOrder, quantity: +e.target.value });
              }}
              value={newOrder?.quantity}
            />
          </div>
          {/* <div className="flex  gap-2 justify-between ">
            <div className=" px-2 py-1">Rate</div>

            <ModalInput
              type={"number"}
              onChange={(e) => {
                setNewOrder({ ...newOrder, rate: e.target.value });
              }}
              value={newOrder?.rate}
            />
          </div> */}
        </div>
      </CustomModal>
    </div>
  );
};

export default Layout2;

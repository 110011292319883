import React from "react";
import { toast } from "react-toastify";
import Layout3 from "../../../components/Layout/Layout3";
import Loading from "../../../components/Loading";
import { ModalInput } from "../../../components/Modal";
import CustomSelect from "../../../components/Select";
import IsPayment from "../../../components/modals/IsPayment";
import {
  createPurchaseOrder,
  getAllCustomers,
  getProductData,
} from "../../../utils/api";
import useStore from "../../../utils/store";

const StaffMarketPurchase = () => {
  const user = useStore((state) => state.user);
  const initialPayment = {
    customerId: "",
    description: "",
    quantity: "",
    rate: "",
    productId: "",
    date: new Date(),
  };
  const [loading, setLoading] = React.useState(false);
  const [newPayment, setNewPayment] = React.useState(initialPayment);
  const [customers, setCustomers] = React.useState([]);
  const [products, setProducts] = React.useState([]);
  const getCustomers = async () => {
    setLoading(true);
    const [success, data] = await getAllCustomers();

    if (success) {
      setCustomers(data);
    }
    setLoading(false);
  };

  const getAllProductsHandler = async () => {
    setLoading(true);
    const [success, data] = await getProductData();
    if (success) {
      setProducts(data);
    }
    setLoading(false);
  };

  const addPaymentHandler = async () => {
    setNewPayment({ ...newPayment, type: "staff" });
    setNewPayment({ ...newPayment, completedById: user.staff._id });
    if (
      newPayment.customerId === "" ||
      newPayment.rate === "" ||
      newPayment.quantity === "" ||
      newPayment.rate === "" ||
      newPayment.productId === "" ||
      newPayment.completedById === "" ||
      newPayment.type === ""
    ) {
      toast.error("Please fill all the fields");
      return;
    }
    setLoading(true);
    const [success, data] = await createPurchaseOrder({
      ...newPayment,
      type: "staff",
      completedById: user.staff._id,
    });
    if (success) {
      setNewPayment(initialPayment);
      toast.success("Purchase Order added successfully");
      setLoading(false);
      return data;
    } else {
      toast.error(data.message);
      setLoading(false);
      return null;
    }
  };

  React.useEffect(() => {
    getCustomers();
    getAllProductsHandler();
  }, []);
  return (
    <Layout3 title={user?.staff?.userName}>
      {loading && <Loading />}

      <div className="flex md:items-center justify-center gap-3 md:flex-row flex-col bg-white py-10 px-10">
        <div className="flex flex-col gap-2">
          <div className="bg-secondary border-2 border-black px-2 py-1">
            Customer
          </div>
          <CustomSelect
            onChange={(e) => {
              setNewPayment({ ...newPayment, customerId: e.target.value });
            }}
            options={customers
              .filter((item) => item?.type === "Customer")
              .map((customer) => ({
                value: customer._id,
                label: `${customer.userName} (${customer?.group?.name})`,
              }))}
            value={newPayment.customerId}
          />
        </div>
        <div className="flex flex-col gap-2">
          <div className="bg-secondary border-2 border-black px-2 py-1">
            description
          </div>
          <ModalInput
            width={"w-full"}
            value={newPayment?.description}
            onChange={(e) => {
              setNewPayment({ ...newPayment, description: e.target.value });
            }}
          />
        </div>
        <div className="flex flex-col gap-2">
          <div className="bg-secondary border-2 border-black px-2 py-1">
            Product
          </div>
          <CustomSelect
            onChange={(e) => {
              setNewPayment({ ...newPayment, productId: e.target.value });
            }}
            options={
              products
                ?.filter((item) => item?.productType === "Purchase")
                .map((product) => ({
                  value: product._id,
                  label: `${product.productName} (${product?.group})`,
                })) || []
            }
            value={newPayment.productId}
          />
        </div>
        <div className="flex flex-col gap-2">
          <div className="bg-secondary border-2 border-black px-2 py-1">
            Quantity
          </div>
          <ModalInput
            width={"w-full"}
            value={newPayment?.quantity}
            onChange={(e) => {
              setNewPayment({ ...newPayment, quantity: e.target.value });
            }}
            type={"number"}
          />
        </div>
        <div className="flex flex-col gap-2">
          <div className="bg-secondary border-2 border-black px-2 py-1">
            Rate
          </div>
          <ModalInput
            width={"w-full"}
            value={newPayment?.rate}
            onChange={(e) => {
              setNewPayment({ ...newPayment, rate: e.target.value });
            }}
            type={"number"}
          />
        </div>
        <div className="flex flex-col gap-2">
          <div className="bg-secondary border-2 border-black px-2 py-1">
            Date
          </div>
          <ModalInput
            width={"w-full"}
            value={newPayment?.date}
            onChange={(e) => {
              setNewPayment({ ...newPayment, date: e.target.value });
            }}
            type={"date"}
          />
        </div>

        <IsPayment
          defaultfuntion={addPaymentHandler}
          canOpen={
            newPayment?.customerId &&
            newPayment?.productId &&
            newPayment?.quantity &&
            newPayment?.rate &&
            newPayment?.description
          }
          type="Payment"
        />
      </div>
    </Layout3>
  );
};

export default StaffMarketPurchase;

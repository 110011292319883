import React from "react";
import Layout from "../../components/Layout/Layout";
import Loading from "../../components/Loading";
import DispatchTableCompleted from "../../components/dispatchTableCompleted";
import { orderDispatchAdmin } from "../../utils/api";
import useStore from "../../utils/store";

const AdminDispatch = () => {
  const [dash, setDash] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const startDate = useStore((state) => state.startDate);
  const endDate = useStore((state) => state.endDate);
  const getData = async () => {
    setLoading(true);
    const [success, data] = await orderDispatchAdmin(startDate, endDate);
    if (success) {
      setDash(data);
    }
    setLoading(false);
  };

  React.useEffect(() => {
    getData();
  }, [startDate, endDate]);

  return (
    <Layout>
      {loading && <Loading />}
      <DispatchTableCompleted type="dispatch" data={dash} />
    </Layout>
  );
};

export default AdminDispatch;

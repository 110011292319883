import React, { useState } from "react";
import {
  deleteInvoice,
  getClientInvoices,
  getCustomerInvoices,
} from "../../utils/api";
import { Button } from "@mui/material";
import CustomModal from "../Modal";
import Table from "../Table";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

const InvoicesModal = ({ id, client }) => {
  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [data, setData] = useState([]);
  const [open, setOpen] = useState(false);
  const getInvoicesHandler = async () => {
    setLoading(true);
    const [success, data] = client
      ? await getClientInvoices(id)
      : await getCustomerInvoices(id);
    if (success) {
      setData(data);
    }
    setLoading(false);
  };
  console.log(data);
  return (
    <div>
      <Button
        variant="contained"
        color="primary"
        onClick={() => {
          setOpen(true);
          getInvoicesHandler();
        }}
      >
        Invoices
      </Button>
      <CustomModal
        width={"md:w-[50rem]"}
        open={open}
        setOpen={setOpen}
        title="Invoices"
        button={<></>}
      >
        <Table
          columns={[
            {
              title: "INVOICE No.",
              dataIndex: "invoiceNumberString",
            },
            {
              title: "DATE",
              dataIndex: "invoiceDate",
              render: (date) =>
                new Date(date?.invoiceDate).toLocaleDateString("en-GB"),
            },
            {
              title: "CUSTOMER",
              dataIndex: "customer",
              render: (customer) => <>{customer?.customer?.userName}</>,
            },
            {
              title: "TOTAL",
              dataIndex: "grandTotal",
              render: (total) => <>{total?.grandTotal?.toFixed(2)}</>,
            },
            {
              title: "Download",
              render: (row) => (
                <Link
                  to={`/admin/invoice/${row._id}`}
                  target="_blank"
                  className="text-black"
                >
                  View Invoice
                </Link>
              ),
            },
            {
              title: "Actions",
              render: (row) => (
                <Button
                  variant="contained"
                  color="error"
                  onClick={async () => {
                    setLoading2(true);
                    const [success, data] = await deleteInvoice(row._id);
                    if (success) {
                      toast.success("Invoice Deleted Successfully");
                      getInvoicesHandler();
                    }
                    setLoading2(false);
                  }}
                >
                  {loading2 ? "Deleting..." : "Delete"}
                </Button>
              ),
            },
          ]}
          data={data}
        />
      </CustomModal>
    </div>
  );
};

export default InvoicesModal;

import { StyleSheet } from "@react-pdf/renderer";

export const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    backgroundColor: "#FFFFFF",
    padding: 25,
    fontFamily: "WorkSans",
  },
  main: {
    textAlign: "center",
    border: "solid",
    height: "100%",
    borderWidth: 1,
  },
  mainText: {
    fontWeight: "bold",
    textDecoration: "underline",
    fontSize: "12px",
  },

  border: {
    height: "1px",
    width: "100%",
    borderBottom: "solid",
    borderBottomWidth: 1,
  },
  text: {
    fontWeight: "bold",
    fontSize: "10px",
    textAlign: "center",
  },
  column: {
    display: "flex",
    flexDirection: "column",
    gap: 3,
    alignItems: "center",
    justifyContent: "center",
  },
  row: {
    display: "flex",
    flexDirection: "row",
    gap: 10,
  },
});

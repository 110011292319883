export const formatDate = (date) => {
  if (date === undefined || date === null) return "No date available";

  return new Date(date)
    ?.toISOString()
    ?.replace(/T.*/, "")
    ?.split("-")
    ?.reverse()
    ?.join("-");
};

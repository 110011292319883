import React from "react";

import { Button } from "@mui/material";

import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import Table from "../Table";
import { sendMessage } from "../../utils/api";

const CustomerMessage = ({ customerData }) => {
  const [checked, setChecked] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);

  const handleSend = async () => {
    if (isLoading) return;
    if (checked.length < 1)
      return toast.error("Please select at least one user");
    setIsLoading(true);
    const [success, res] = await sendMessage({
      data: checked,
    });
    if (success) {
      setChecked([]);
      toast.success("Message sent successfully");
    }
    setIsLoading(false);
  };

  return (
    <div className="flex flex-col md:w-1/2">
      {checked.length > 0 && (
        <div className="flex justify-between items-center">
          <Button onClick={handleSend} variant="contained" color="primary">
            {isLoading
              ? "Sending..."
              : `Send Message to ${checked.length} ${
                  checked.length > 1 ? "users" : "user"
                }`}
          </Button>
        </div>
      )}
      <Table
        columns={[
          {
            title: "Customer Name",
            dataIndex: "name",
            render: (record) => (
              <p>
                {record?.name}{" "}
                {record?.clients?.length > 0 && (
                  <>
                    ({record?.clients?.map((item) => item.userName)?.join(",")})
                  </>
                )}
              </p>
            ),
          },
          {
            title: "Phone Number",
            dataIndex: "phoneNumber",
          },
          {
            title: "Balance",
            dataIndex: "balance",
            render: (record) => <div>{record?.balance?.toFixed(2)}</div>,
          },
          {
            title: "Message",
            render: (item) => (
              <input
                type="checkbox"
                checked={checked.find((i) => i.id === item._id) ? true : false}
                onChange={(e) => {
                  if (e.target.checked) {
                    setChecked([
                      ...checked,
                      {
                        id: item._id,
                        // amount: item.balance,
                      },
                    ]);
                  } else {
                    setChecked(checked.filter((i) => i.id !== item._id));
                  }
                }}
              />
            ),
          },
          {
            title: "Tag",
            dataIndex: "tag",
            td: (record) => (
              <td
                className={`border px-2 py-1 border-black ${
                  record?.wagon === 0
                    ? "bg-yellow-400"
                    : record?.tag < 0
                    ? "bg-red-500"
                    : "bg-green-500"
                }`}
              >
                <Link to={`/admin/customer?id=${record?._id}`}>
                  {record?.tag?.toFixed(2)}
                </Link>
              </td>
            ),
          },
        ]}
        data={customerData}
      />
    </div>
  );
};

export default CustomerMessage;

import { Typography } from "@mui/material";

import { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import useStore from "../utils/store";

const Home = () => {
  const user = useStore((state) => state.user);
  const navigate = useNavigate();
  useEffect(() => {
    if (user) {
      if (user.type === "Admin") {
        navigate("/admin");
      }
      if (user.type === "Customer") {
        navigate("/client");
      }
      if (user.type === "MarketingStaff") {
        navigate("/staff/marketing");
      }
      if (user.type === "TradingStaff") {
        navigate("/staff/trading");
      }
      if (user.type === "TagStaff") {
        navigate("/staff/tag");
      }
      if (user.type === "StancilStaff") {
        navigate("/staff/stancil");
      }
      if (user.type === "OtherStaff") {
        navigate("/staff/other");
      }
    } else {
      navigate("/login");
    }
  }, [user, navigate]);

  return (
    <div className="flex flex-col gap-6  items-center">
      <Typography variant="h1">Links</Typography>
      <Link to="/admin" className="border-b border-primary">
        Admin
      </Link>
      <Link to="/login" className="border-b border-primary">
        Login
      </Link>
      <Link to="/client" className="border-b border-primary">
        Client
      </Link>
      <Link to="/staff/marketing" className="border-b border-primary">
        Staff market
      </Link>
      <Link to="/staff/manufacturing" className="border-b border-primary">
        Staff manufacturing
      </Link>
    </div>
  );
};

export default Home;

import {
  PDFViewer,
  Text,
  Document,
  Page,
  Font,
  View,
  pdf,
} from "@react-pdf/renderer";
import { saveAs } from "file-saver";
import React, { useEffect } from "react";
import Loading from "../../components/Loading";
import useStore from "../../utils/store";
import { getAllDispatch } from "../../utils/api";
import { useParams } from "react-router-dom";
import WorkRegular from "../../assets/fonts/WorkSans-Regular.ttf";
import WorkBold from "../../assets/fonts/WorkSans-Bold.ttf";
import WorkBoldItalic from "../../assets/fonts/WorkSans-BoldItalic.ttf";
import WorkSemiBold from "../../assets/fonts/WorkSans-SemiBold.ttf";
Font.register({
  family: "WorkSans",
  src: WorkRegular,

  fonts: [
    { src: WorkRegular },
    { src: WorkBold, fontWeight: "bold" },
    { src: WorkBoldItalic, fontWeight: "bold", fontStyle: "italic" },
    { src: WorkSemiBold, fontWeight: "semibold" },
  ],
});
const MyDocument = ({ dash, params }) => {
  return (
    <Document title={`${dash?.customer?.userName}-challan`}>
      <Page
        size={[220, 800]}
        style={{
          padding: 10,
          display: "flex",
          flexDirection: "column",
          justifyContent: "start",
          alignItems: "center",
          gap: 20,
          fontFamily: "WorkSans",
        }}
      >
        {new Array(3).fill(0).map((_, index) =>
          index % 2 !== 0 ? (
            <View
              key={index}
              style={{
                height: 1,
                width: "100%",
                borderBottom: "1px dashed black",
              }}
            ></View>
          ) : (
            <View
              key={index}
              style={{
                display: "flex",
                flexDirection: "column",
                gap: 20,
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Text
                style={{
                  fontSize: 24,
                  textAlign: "center",
                  fontWeight: "bold",
                }}
              >
                Prabhu Labels
              </Text>

              <Text style={{ fontSize: 23, textAlign: "center" }}>
                {dash?.customer?.userName} ({dash?.group})
              </Text>
              <Text style={{ fontSize: 20, textAlign: "center" }}>
                Date: {new Date(dash?.updatedAt).toLocaleDateString("en-IN")}
              </Text>
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Text style={{ fontSize: 20, textAlign: "center" }}>
                  {dash?.product?.productName} -
                </Text>
                <Text style={{ fontSize: 20, textAlign: "center" }}>
                  {dash?.quantity}
                </Text>
              </View>
              <Text style={{ fontSize: 20, textAlign: "center" }}>
                Weight - {params?.weight} kg
              </Text>
              <Text style={{ fontSize: 12, textAlign: "center" }}>
                Call for any query: 9417771111
              </Text>
              <br />
              <br />
              <br />
              <br />
            </View>
          )
        )}
      </Page>
    </Document>
  );
};

const Challan = (id) => {
  const [dash, setDash] = React.useState(null);
  const params = useParams();
  const [loading, setLoading] = React.useState(false);
  const startDate = useStore((state) => state.startDate);
  const endDate = useStore((state) => state.endDate);
  const getData = async () => {
    setLoading(true);
    const [success, data] = await getAllDispatch(startDate, endDate);
    if (success) {
      const currentItem = data.find((item) => item._id === params.id);

      setDash(currentItem?.orders?.find((order) => order._id === params.order));
    }
    setLoading(false);
  };
  console.log(dash);

  const saveFile = () => {
    // This does the trick!
    pdf(<MyDocument dash={dash} params={params} />)
      .toBlob()
      .then((blob) => saveAs(blob, `${dash?.customer?.userName}-challan.pdf`));
  };
  useEffect(() => {
    getData();
  }, [startDate, endDate]);

  if (loading) {
    return <Loading />;
  }
  return (
    <div>
      <button
        onClick={saveFile}
        className="bg-primary w-full py-2 text-center text-white font-medium text-sm h-10"
      >
        Save File
      </button>

      <PDFViewer
        className="
"
        style={{ width: "100%", height: "calc(100vh - 40px)" }}
      >
        <MyDocument dash={dash} params={params} />
      </PDFViewer>
    </div>
  );
};

export default Challan;

const Dash = ({ title, value }) => {
  return (
    <div className="border-2 h-fit flex-1 border-black rounded-lg overflow-hidden bg-primary">
      <div className="bg-[#fff101] flex flex-col items-center px-4 py-2 xl:py-4 xl:px-6 2xl:px-8 2xl:py-6 rounded-b-lg">
        <h4>Total</h4>
        <h2 className=" xl:text-lg 2xl:text-xl font-semibold">{title}</h2>
      </div>
      {(value || value === 0) && (
        <h2 className="text-3xl xl:text-4xl 2xl:text-5xl font-semibold text-center py-4 xl:py-6 2xl:py-8 bg-primary text-white">
          {value}
        </h2>
      )}
    </div>
  );
};

export default Dash;

import Dash from "./Dash";

import Search from "./Search";
import CustomModal, { ModalInput, ModalSelect } from "./Modal";
import { RiArrowDownFill } from "react-icons/ri";
const CustomerDetailsCom = ({ client, open, setOpen }) => {
  return (
    <>
      <div
        className={`
      ${client && "pt-6"}
      flex gap-4 flex-col  pb-6 w-2/3 mx-auto`}
      >
        <h1 className="text-xl bg-yellow-500 text-red-600 border-black font-semibold text-left mr-auto border-2 px-3 py-1">
          BCW
        </h1>
        <div className="flex gap-6  flex-wrap">
          <Dash />
          <Dash />
          <Dash />
        </div>
      </div>
      <div className="flex gap-10 items-start flex-col md:flex-row ">
        {!client && <Search />}
        <div className="overflow-x-auto w-full whitespace-nowrap">
          <table className=" border-separate border-spacing-3 ">
            <thead>
              <tr className="">
                <th className=" bg-secondary font-medium">Date</th>
                <th className="bg-secondary font-medium">description</th>
                <th className="bg-secondary font-medium">Items</th>
                <th className="bg-secondary font-medium">Qnt</th>
                <th className="bg-secondary font-medium">Rate</th>
                <th className="bg-secondary font-medium">Gst</th>
                <th className="bg-secondary font-medium">Total</th>

                <th className="bg-red-500 text-white font-medium">
                  Pdf
                  <RiArrowDownFill className="inline-block text-2xl text-black" />
                </th>
              </tr>
            </thead>
            <tbody className="">
              <tr>
                <td className="border-2 border-black text-center ">
                  12/12/2021
                </td>
                <td className="border-2 border-black text-center"></td>
                <td className="border-2 border-black text-center">Tag</td>
                <td className="border-2 border-black text-center">5000</td>
                <td className="border-2 border-black text-center">.10</td>
                <td className="border-2 border-black text-center">.12%</td>
                <td className="border-2 border-black text-center">total</td>
                <td className="">
                  <button className="bg-primary text-white px-2">Edit</button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <CustomModal open={open} setOpen={setOpen}>
          <ModalInput title="Customer Name" />
          <ModalInput title="Mobile No." />

          <ModalSelect title="Group" options={["Ludhiana", "Mansa"]} />
        </CustomModal>
      </div>
    </>
  );
};

export default CustomerDetailsCom;

import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import logo from "../../assets/logo.png";
import useStore from "../../utils/store";
import { Button } from "@mui/material";

const Layout4 = ({ children, type, title, subTitle }) => {
  const user = useStore((state) => state.user);
  const setUser = useStore((state) => state.setUser);
  const navigate = useNavigate();
  useEffect(() => {
    if (!user || user?.type !== type) {
      navigate("/login");
    }
  }, [user, navigate]);
  return (
    <div className="flex flex-col  h-[100dvh] bg-layoutbg">
      <div className="flex gap-2 justify-between items-center px-3 md:px-6 ">
        <div className="flex gap-6 items-center ">
          <img src={logo} alt="logo" className="md:w-72 md:h-44 w-32  py-6 " />
          <h1 className="md:text-2xl  text-lg py-6 font-medium text-center text-primary">
            {title}
          </h1>
        </div>
        {/* logout */}
        <div className="flex gap-6 items-center">
          {subTitle && (
            <h1 className="md:text-2xl  text-lg py-6 font-medium text-center text-primary">
              {subTitle}
            </h1>
          )}
          <Button
            variant="contained"
            color="error"
            onClick={() => {
              setUser(null);
              navigate("/login");
            }}
          >
            Logout
          </Button>
        </div>
      </div>
      <div className="overflow-y-auto px-6 py-3">{children}</div>
    </div>
  );
};

export default Layout4;

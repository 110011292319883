import { ThemeProvider, createTheme } from "@mui/material";
import { Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";
import ChangePassword from "./pages/ChangePassword";
import Login from "./pages/Login";
import CustomerDetails from "./pages/admin/CustomerDetails";
import Dashboard from "./pages/admin/Dashboard";
import Products from "./pages/admin/Products";
import Staff from "./pages/admin/Staff";
import Home2 from "./pages/client/Home";
import StaffMarketDash from "./pages/staff/StaffMarket/StaffMarketDash";

import Home from "./pages/Home";
import Logout from "./pages/Logout";
import ADDCustomer from "./pages/admin/ADDCustomer";
import AddProductGST from "./pages/admin/AddProductGST";
import AddProductPrice from "./pages/admin/AddProductPrice";
import AdminDispatch from "./pages/admin/AdminDispatch";
import AdminPurchase from "./pages/admin/AdminPurchase";
import Client from "./pages/admin/Client";
import ClientADD from "./pages/admin/ClientADD";
import Contact from "./pages/admin/Contact";
import Customer from "./pages/admin/Customer";
import DispatchByAdmin from "./pages/admin/Dispatch";
import DispatchPdfComplete from "./pages/admin/DispatchPdfComplete";
import Group from "./pages/admin/Group";
import InvoiceConvertor from "./pages/admin/InvoiceConvertor";
import Invoices from "./pages/admin/Invoices";
import AdminOrder from "./pages/admin/Orders";
import AdminPayment from "./pages/admin/Payment";
import Pdfconverter from "./pages/admin/PdFConverter";
import ProductPdf from "./pages/admin/ProductPdf";
import ProfitPdf from "./pages/admin/ProfitPdf";
import ReceivedPdf from "./pages/admin/ReceivedPdf";
import SalesPdf from "./pages/admin/SalesPdf";
import PurchasePdf from "./pages/admin/SalesPurchase";
import StaffPdf from "./pages/admin/StaffPdf";
import PdfConverterClient from "./pages/client/PdfConverterClient";
import Dispatch from "./pages/staff/Dispatch";
import DispatchPdf from "./pages/staff/DispatchPdf";
import OtherStaff from "./pages/staff/OtherStaff";
import PdfconverterStaff from "./pages/staff/PdfCoverterStaff";
import StaffManufacturing from "./pages/staff/StaffManufacturing";
import MarketADDCustomer from "./pages/staff/StaffMarket/ADDCustomer";
import DispatchMarket from "./pages/staff/StaffMarket/Dispatch";
import MarkInvoices from "./pages/staff/StaffMarket/Invoices";
import StaffMarketOrder from "./pages/staff/StaffMarket/StaffMarketOrder";
import StaffMarketPayment from "./pages/staff/StaffMarket/StaffMarketPayment";
import StaffMarketPurchase from "./pages/staff/StaffMarket/StaffMarketPurchase";
import Stancil from "./pages/staff/StancilStaff";
import TagStaff from "./pages/staff/TagStaff";
import TradingStaff from "./pages/staff/TradingStaff";
import Challan from "./pages/admin/Challan";
import { useEffect } from "react";
import Ledger from "./pages/admin/Ledger";

function App() {
  const theme = createTheme({
    palette: {
      primary: {
        main: "#1eb14b",
        contrastText: "#fff",
      },
    },
  });

  return (
    <>
      <ThemeProvider theme={theme}>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/login" element={<Login />} />
          <Route path="/admin" element={<Dashboard />} />
          <Route path="/admin/purchase" element={<AdminPurchase />} />
          <Route path="/admin/staff" element={<Staff />} />
          <Route path="/admin/client/add" element={<ClientADD />} />
          <Route path="/admin/client" element={<Client />} />
          <Route path="/admin/orders" element={<AdminOrder />} />
          <Route path="/admin/products" element={<Products />} />
          <Route path="/admin/product-gst" element={<AddProductGST />} />
          <Route path="/admin/product-price" element={<AddProductPrice />} />
          <Route path="/admin/customer" element={<Customer />} />
          <Route path="/admin/contact" element={<Contact />} />
          <Route path="/admin/payments" element={<AdminPayment />} />
          <Route path="/logout" element={<Logout />} />
          <Route path="/admin/group" element={<Group />} />
          <Route path="/admin/pdf/sales" element={<SalesPdf />} />
          <Route path="/admin/pdf/purchase" element={<PurchasePdf />} />
          <Route path="/admin/pdf/products/:id" element={<ProductPdf />} />
          <Route path="/admin/pdf/profit" element={<ProfitPdf />} />
          <Route path="/admin/pdf/received" element={<ReceivedPdf />} />
          <Route path="/admin/dispatch" element={<AdminDispatch />} />
          <Route
            path="/admin/dispatch/challan/:id/:order/:weight"
            element={<Challan />}
          />
          <Route path="/admin/customer/:id" element={<CustomerDetails />} />
          <Route path="/admin/invoice" element={<Invoices />} />
          <Route path="/staff/trading" element={<TradingStaff />} />
          <Route
            path="/admin/dispatch/complete"
            element={<DispatchByAdmin />}
          />
          <Route path="/admin/customer/add" element={<ADDCustomer />} />
          <Route path="/staff/tag" element={<TagStaff />} />
          <Route path="/staff/stancil" element={<Stancil />} />
          <Route path="/staff/other" element={<OtherStaff />} />
          <Route
            path="/staff/marketing/purchase"
            element={<StaffMarketPurchase />}
          />
          <Route
            path="/staff/marketing/dispatch"
            element={<DispatchMarket />}
          />
          <Route
            path="/staff/marketing/customer/add"
            element={<MarketADDCustomer />}
          />
          <Route path="/pdf/customer/:id" element={<Pdfconverter />} />
          <Route path="/pdf/ledger/:type/:id" element={<Ledger />} />
          <Route
            path="/admin/pdf/client/:id"
            element={<PdfConverterClient />}
          />
          <Route
            path="/admin/pdf/client/:all/:id"
            element={<PdfConverterClient />}
          />

          <Route path="/pdf/client/:id" element={<PdfConverterClient />} />

          <Route path="/pdf/staff/:id" element={<PdfconverterStaff />} />
          <Route path="/pdf/admin/complete" element={<DispatchPdfComplete />} />
          <Route path="/staff/dispatch" element={<Dispatch />} />
          <Route path="/pdf/staff/dispatch" element={<DispatchPdf />} />
          <Route path="/pdf/admin/staff/:type/:id" element={<StaffPdf />} />

          <Route path="/client" element={<Home2 />} />
          <Route path="/staff/marketing" element={<StaffMarketDash />} />
          <Route path="/staff/marketing/invoices" element={<MarkInvoices />} />
          <Route
            path="/staff/marketing/payment"
            element={<StaffMarketPayment />}
          />
          <Route
            path="/staff/marketing/orders"
            element={<StaffMarketOrder />}
          />
          <Route path="/staff/manufacturing" element={<StaffManufacturing />} />
          <Route path="/change-password" element={<ChangePassword />} />
          <Route path="/admin/invoice/:id" element={<InvoiceConvertor />} />
        </Routes>
        <ToastContainer />
      </ThemeProvider>
    </>
  );
}

export default App;

import { Link, useLocation } from "react-router-dom";
import logo from "../../assets/logo.png";
import React from "react";
import useStore from "../../utils/store";

const Sidebar = ({ links, open, setOpen }) => {
  const location = useLocation();
  const ref = React.useRef(null);
  const startDate = useStore((state) => state.startDate);
  const user = useStore((state) => state.user);
  const endDate = useStore((state) => state.endDate);
  const setStartDate = useStore((state) => state.setStartDate);
  const setEndDate = useStore((state) => state.setEndDate);
  React.useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (open && ref.current && !ref.current.contains(e.target)) {
        setOpen(false);
      }
    };

    document.addEventListener("mousedown", checkIfClickedOutside);

    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [open]);

  return (
    <>
      {/* <div className="w-[15rem] md:flex hidden h-full py-4 px-6  flex-col gap-6">
        <img src={logo} alt="logo" className="w-full mx-auto" />

        <div className="flex flex-col gap-3 whitespace-nowrap">
          {links?.map((item, index) => (
            <Link
              to={item.path}
              key={index}
              className={` rounded py-1.5 px-4 ${
                location.pathname === item.path
                  ? "bg-primary text-white"
                  : item.path === "/logout"
                  ? "bg-red-600 text-white"
                  : ""
              } `}
            >
              {item.title}
            </Link>
          ))}
        </div>
      </div> */}

      <div
        className={`w-[90%] md:w-[15rem]  transform transition-all duration-300 overflow-y-auto
              ${open ? "translate-x-0 ease-out" : "-translate-x-full ease-in"}
          fixed h-full py-4 px-6 flex   flex-col gap-6 z-50 bg-[#eeeeee]`}
        ref={ref}
      >
        <img src={logo} alt="logo" className="w-1/2 mx-auto" />

        <div className="flex flex-col gap-3">
          {user?.type === "Admin" && (
            <div className="flex md:hidden gap-4 items-center">
              <input
                type="date"
                value={startDate}
                onChange={(e) => {
                  setStartDate(e.target.value);
                  window.location.reload();
                }}
                placeholder="Start Date"
                className="border-black text-sm py-2 px-3 border-2"
                max={endDate}
              />
              <p className="text-primary text-base">to</p>
              <input
                type="date"
                value={endDate}
                min={startDate}
                onChange={(e) => {
                  setEndDate(e.target.value);
                  window.location.reload();
                }}
                placeholder="End Date"
                className="border-black text-sm py-2 px-3 border-2"
              />
            </div>
          )}

          {links?.map((item, index) => (
            <Link
              to={item.path}
              key={index}
              className={` rounded py-1.5 px-4
              
              ${
                location.pathname === item.path ? "bg-primary text-white" : ""
              } `}
            >
              {item.title}
            </Link>
          ))}
        </div>
      </div>
    </>
  );
};

export default Sidebar;

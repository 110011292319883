import Layout4 from "../../components/Layout/Layout4";

const StaffManufacturing = () => {
  return (
    <Layout4>
      <div className="text-primary text-2xl py-6 px-3">Staff Manufacturing</div>{" "}
      <table className="table-auto w-full border-separate border-spacing-3 pr-10">
        <tbody>
          {[1, 1, 1, 1, 1, 1, 1].map((item, index) => (
            <tr key={index}>
              <td className="border-2 border-black text-center ">12/12/2021</td>
              <td className="border-2 border-black min-w-[2rem] text-center">
                {"        "}
              </td>
              <td className="border-2 border-black text-center">Tag</td>
              <td className="border-2 border-black text-center">
                5000000000000000000000000
              </td>

              <td className="">
                <button className="bg-primary text-white px-2">Complete</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </Layout4>
  );
};

export default StaffManufacturing;

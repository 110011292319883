import React, { useState } from "react";
import { toast } from "react-toastify";
import Layout from "../../components/Layout/Layout";
import {
  addGSTToCusPro,
  addRateToCusPro,
  deleteGstToCusPro,
  deleteRateToCusPro,
  getAllCustomers,
  getProductData,
  getProductGstByCustomer,
  getProductPriceByCustomer,
  updateGSTToCusPro,
  updateRateToCusPro,
} from "../../utils/api";

const AddProductPrice = () => {
  const [loading, setLoading] = useState(false);
  const [text, setText] = useState(0);
  const [text2, setText2] = useState(0);
  const [customer, setCustomer] = useState(null);
  const [rate, setRate] = useState(null);
  const [gst, setGst] = useState(null);
  const [product, setProduct] = useState(null);
  const [customers, setCustomers] = useState([]);
  const [products, setProducts] = useState([]);

  const getAllCustomerHandler = async () => {
    setLoading(true);
    const [success, data] = await getAllCustomers();
    if (success) {
      setCustomers(data);
    }
    setLoading(false);
  };
  const getProductsData = async () => {
    setLoading(true);
    const [success, data] = await getProductData();
    if (success) {
      setProducts(data);
    }

    setLoading(false);
  };
  const [search, setSearch] = useState("");
  const getProductPrice = async (data) => {
    setLoading(true);

    const [success, data2] = await getProductPriceByCustomer(data);
    if (success) {
      setRate(data2);
      if (data2) {
        setText(data2?.rate);
      } else {
        setText(0);
      }
    }

    setLoading(false);
  };

  const getProductGSt = async (data) => {
    setLoading(true);

    const [success, data2] = await getProductGstByCustomer(data);
    if (success) {
      setGst(data2);
      if (data2) {
        setText2(data2?.gst);
      } else {
        setText2(0);
      }
    }

    setLoading(false);
  };

  React.useEffect(() => {
    getAllCustomerHandler();
    getProductsData();
  }, []);

  return (
    <Layout>
      <div className="grid md:grid-cols-3 gap-6">
        <div className="border p-3 rounded bg-white border-black">
          <h2 className="border-b pb-3">Client</h2>
          <div className="flex  gap-2 my-3">
            <input
              type="text"
              placeholder="Search"
              className="border border-black flex-1 w-full rounded p-2"
              value={search}
              onChange={(e) => {
                setSearch(e.target.value);
              }}
            />
          </div>
          <div className="flex flex-col gap-2 ">
            {customers.filter(
              (item) =>
                item?.type === "Client" &&
                item?.userName?.toLowerCase().includes(search.toLowerCase())
            )?.length === 0 && <p className="text-center">No Client Found</p>}
          </div>
          <div className="flex flex-col gap-2">
            {customers
              .filter(
                (item) =>
                  item?.type === "Client" &&
                  item?.userName?.toLowerCase().includes(search.toLowerCase())
              )
              ?.map((item, index) => (
                <button
                  className={`flex gap-2  py-1
                ${
                  customer?.userName === item.userName
                    ? "bg-primary text-white px-2 rounded"
                    : "bg-white text-black"
                }
                `}
                  key={index}
                  onClick={() => {
                    setCustomer(item);
                    if (product) {
                      getProductPrice({
                        customerId: item?._id,
                        productId: product?._id,
                      });
                      getProductGSt({
                        customerId: item?._id,
                        productId: product?._id,
                      });
                    }
                  }}
                >
                  <p>{item.userName}</p>
                </button>
              ))}
          </div>
        </div>
        <div className="border p-3 rounded bg-white border-black">
          <h2 className="border-b pb-3">Products</h2>
          <div className="flex flex-col gap-2 mt-3">
            {products?.map((item, index) => (
              <button
                className={`flex gap-2  py-1
                ${
                  product?.productName === item.productName
                    ? "bg-primary text-white px-2 rounded"
                    : "bg-white text-black"
                }
                    `}
                key={index}
                onClick={() => {
                  setProduct(item);
                  if (customer) {
                    getProductPrice({
                      customerId: customer?._id,
                      productId: item?._id,
                    });
                    getProductGSt({
                      customerId: customer?._id,
                      productId: item?._id,
                    });
                  }
                }}
              >
                <p>{item.productName}</p>
              </button>
            ))}
          </div>
        </div>
        <div className="border p-3 rounded bg-white border-black">
          {" "}
          <div className="flex flex-col gap-3">
            <h2 className="border-b pb-3">Rate</h2>
            <div className="flex flex-col gap-2 mt-3">
              <input
                type="number"
                placeholder="Rate"
                className="border border-black rounded p-2"
                disabled={!product || !customer}
                value={text}
                onChange={(e) => {
                  setText(e.target.value);
                }}
              />
              <div className="flex justify-between items-center">
                {rate && (
                  <button
                    disabled={!rate || loading}
                    onClick={async () => {
                      setLoading(true);
                      const [success, data] = await deleteRateToCusPro(
                        rate._id
                      );
                      if (success) {
                        toast.success("Rate deleted successfully");
                        setText(0);
                        setRate(null);
                        getProductPrice({
                          customerId: customer?._id,
                          productId: product?._id,
                        });
                      }
                      setLoading(false);
                    }}
                    className="bg-red-500 text-red-800 bg-opacity-25 rounded px-4 py-2"
                  >
                    Delete
                  </button>
                )}
                <button
                  className="bg-primary text-white rounded p-2 ml-auto"
                  disabled={!product || !customer}
                  onClick={async () => {
                    if (text === null || text === undefined || text === "") {
                      toast.error("Please enter rate");
                    }

                    if (!customer || !product) {
                      toast.error("Please select customer and product");
                    }

                    setLoading(true);
                    if (rate === null) {
                      const [success, data] = await addRateToCusPro({
                        customerId: customer?._id,
                        productId: product?._id,
                        rate: +text,
                      });
                      if (success) {
                        toast.success("Rate added successfully");
                        getProductPrice({
                          customerId: customer?._id,
                          productId: product?._id,
                        });
                      }
                    } else {
                      const [success, data] = await updateRateToCusPro(
                        rate._id,
                        {
                          rate: +text,
                        }
                      );
                      if (success) {
                        toast.success("Rate updated successfully");
                        getProductPrice({
                          customerId: customer?._id,
                          productId: product?._id,
                        });
                      }
                    }
                    setLoading(false);
                  }}
                >
                  {!loading
                    ? rate
                      ? "Update Rate"
                      : "Add Rate"
                    : "Loading..."}
                </button>
              </div>
              <div className="flex flex-col gap-3">
                <h2 className="border-b pb-3">Gst</h2>
                <div className="flex flex-col gap-2 mt-3">
                  <input
                    type="number"
                    placeholder="Rate"
                    className="border border-black rounded p-2"
                    disabled={!product || !customer}
                    value={text2}
                    onChange={(e) => {
                      setText2(e.target.value);
                    }}
                  />
                  <div className="flex justify-between items-center">
                    {gst && (
                      <button
                        disabled={!rate || loading}
                        onClick={async () => {
                          setLoading(true);
                          const [success, data] = await deleteGstToCusPro(
                            gst._id
                          );
                          if (success) {
                            toast.success("GSt deleted successfully");
                            setText2(0);
                            setGst(null);
                            getProductPrice({
                              customerId: customer?._id,
                              productId: product?._id,
                            });
                          }
                          setLoading(false);
                        }}
                        className="bg-red-500 text-red-800 bg-opacity-25 rounded px-4 py-2"
                      >
                        Delete
                      </button>
                    )}
                    <button
                      className="bg-primary text-white rounded p-2 ml-auto"
                      disabled={!product || !customer}
                      onClick={async () => {
                        if (
                          text2 === null ||
                          text2 === undefined ||
                          text2 === ""
                        ) {
                          toast.error("Please enter gst");
                        }

                        if (!customer || !product) {
                          toast.error("Please select customer and product");
                        }

                        setLoading(true);
                        if (gst === null) {
                          const [success, data] = await addGSTToCusPro({
                            customerId: customer?._id,
                            productId: product?._id,
                            gst: +text2,
                          });
                          if (success) {
                            toast.success("Gst added successfully");
                            getProductGSt({
                              customerId: customer?._id,
                              productId: product?._id,
                            });
                          }
                        } else {
                          const [success, data] = await updateGSTToCusPro(
                            gst._id,
                            {
                              gst: +text2,
                            }
                          );
                          if (success) {
                            toast.success("Gst updated successfully");
                            getProductGSt({
                              customerId: customer?._id,
                              productId: product?._id,
                            });
                          }
                        }
                        setLoading(false);
                      }}
                    >
                      {!loading
                        ? gst
                          ? "Update Gst"
                          : "Add Gst"
                        : "Loading..."}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default AddProductPrice;

import React from "react";
import { Link } from "react-router-dom";
import Table from "../Table";
import classNames from "classnames";
import CustomerMessage from "./CustomerMessage";

const TotalCustomers = ({
  totalCustomers,
  customerData,
  getCustomerDataById,
}) => {
  return (
    <div>
      {totalCustomers && (
        <div className="flex flex-col md:flex-row gap-6 justify-between ">
          <Table
            data={totalCustomers}
            columns={
              customerData.length > 0
                ? [
                    {
                      title: "Group Name",
                      dataIndex: "groupName",
                      render: (record) => (
                        <button
                          onClick={() => getCustomerDataById(record._id)}
                          className={` border-b  ${
                            customerData?.[0].id === record._id
                              ? "text-green-600 font-bold border-green-600"
                              : `text-blue-900 border-blue-900`
                          }`}
                        >
                          {record.groupName}
                        </button>
                      ),
                    },
                    {
                      title: "Total Customers",
                      dataIndex: "customers",
                    },
                    {
                      title: "Products",
                      dataIndex: "products",
                      render: (record) =>
                        customerData?.[0].id === record._id && (
                          <div className="flex flex-col gap-2">
                            {record.products.map((item, index) => (
                              <p key={index}>
                                {item.productName}({item.quantity})
                              </p>
                            ))}
                            <p
                              className={classNames(
                                "font-bold ",
                                customerData?.reduce(
                                  (acc, item) => acc + item.balance,
                                  0
                                ) < 0
                                  ? "text-red-700"
                                  : "text-green-700"
                              )}
                            >
                              Total Balance :{" "}
                              {customerData
                                ?.reduce((acc, item) => acc + item.balance, 0)
                                ?.toFixed(2)}
                            </p>
                          </div>
                        ),
                    },
                  ]
                : [
                    {
                      title: "Group Name",
                      dataIndex: "groupName",
                      render: (record) => (
                        <button
                          onClick={() => getCustomerDataById(record._id)}
                          className="text-blue-900 border-b border-blue-900"
                        >
                          {record.groupName}
                        </button>
                      ),
                    },
                    {
                      title: "Total Customers",
                      dataIndex: "customers",
                    },
                  ]
            }
          />
          {customerData.length > 0 && (
            <CustomerMessage customerData={customerData} />
          )}
        </div>
      )}
    </div>
  );
};

export default TotalCustomers;

import React, { useState } from "react";

import { getAllCustomerGroups, getAllCustomers } from "../../../utils/api";

import Layout3 from "../../../components/Layout/Layout3";
import Loading from "../../../components/Loading";
import Table from "../../../components/Table";
import ClientModal from "../../../components/modals/ClientModal";
import useStore from "../../../utils/store";

const MarketADDCustomer = () => {
  const [open, setOpen] = useState(false);
  const [customers, setCustomers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState(null);
  const [groups, setGroups] = useState([]);
  const user = useStore((state) => state.user);
  const getAllCustomerHandler = async () => {
    setLoading(true);
    const [success, data] = await getAllCustomers();
    if (success) {
      setCustomers(
        data.map((item) => ({
          ...item,
          name: `${item.userName} ${
            item?.group?.name !== undefined ? `(${item?.group?.name})` : ""
          }`,
        }))
      );
    }
    setLoading(false);
  };

  const getAllCustomerGroupsHandler = async () => {
    setLoading(true);
    const [success, data] = await getAllCustomerGroups();
    if (success) {
      setGroups(data);
    }
    setLoading(false);
  };

  React.useEffect(() => {
    getAllCustomerHandler();
    getAllCustomerGroupsHandler();
  }, []);

  return (
    <Layout3
      title={user.staff.userName}
      text="Add New Customer"
      onClick={() => {
        setOpen(true);
      }}
    >
      {loading && <Loading />}

      <ClientModal
        open={open}
        setOpen={setOpen}
        reload={getAllCustomerHandler}
        customers={customers}
        groups={groups}
        customer
      />
    </Layout3>
  );
};

export default MarketADDCustomer;

const EditClient = ({ item, reload, setItem, customers, groups }) => {
  const [edit, setEdit] = React.useState(false);
  const [open2, setOpen2] = React.useState(false);

  return (
    <div className="flex flex-col gap-10">
      <div className="flex flex-col gap-3">
        <Table
          data={[item]}
          columns={[
            {
              title: "Username",
              dataIndex: "userName",
            },
            {
              title: "Phone Number",
              dataIndex: "phoneNumber",
            },
            {
              title: "Image",
              dataIndex: "image",
              render: (item) => (
                <a href={item.image} target="_blank" rel="noreferrer">
                  {item.image ? "View" : ""}
                </a>
              ),
            },
            // {
            //   title: "Action",
            //   render: (item) => {
            //     return (
            //       <div className="flex gap-2">
            //         <button
            //           onClick={() => {
            //             setItem(item);
            //             setEdit(true);
            //           }}
            //           className="border mr-2 border-black px-4 py-1 bg-yellow-400 text-xl "
            //         >
            //           Edit
            //         </button>
            //         <button
            //           onClick={async () => {
            //             const [success, data] = await deleteCustomer(item?._id);
            //             if (success) {
            //               setItem(null);
            //               reload();
            //               toast.success("Deleted");
            //             }
            //           }}
            //           className="border mr-2 border-black px-4 py-1 bg-red-500 text-xl "
            //         >
            //           Delete
            //         </button>{" "}
            //         <button
            //           onClick={() => {
            //             setOpen2(true);
            //           }}
            //           className="border mr-2 border-black px-4 py-1 bg-secondary text-xl "
            //         >
            //           Reset
            //         </button>
            //         {open2 && (
            //           <Confirm
            //             open={open2}
            //             setOpen={setOpen2}
            //             onClick={async () => {
            //               const [success, data] = await resetcustomer(
            //                 item?._id
            //               );
            //               if (success) {
            //                 setOpen2(false);
            //                 setItem(null);
            //                 reload();
            //                 toast.success("Reset Complete");
            //               }
            //             }}
            //           />
            //         )}
            //       </div>
            //     );
            //   },
            // },
          ]}
        />

        <div className="border border-black bg-green-600 px-2 py-1 w-fit">
          Clients
        </div>
        <Table
          data={item?.access}
          columns={[
            {
              title: "Username",
              dataIndex: "userName",
            },
            {
              title: "Phone Number",
              dataIndex: "phoneNumber",
            },
            // {
            //   title: "Action",
            //   render: (item2) => {
            //     return (
            //       <div className="flex gap-2">
            //         <button
            //           className="bg-red-500 px-2 py-1  border border-black"
            //           onClick={async () => {
            //             const [success, data] = await updateCustomer(
            //               item?._id,
            //               {
            //                 ...item,
            //                 access: item?.access.filter(
            //                   (item3) => item3?._id !== item2?._id
            //                 ),
            //               }
            //             );
            //             if (success) {
            //               setItem({
            //                 ...item,
            //                 access: item?.access.filter(
            //                   (item3) => item3?._id !== item2?._id
            //                 ),
            //               });
            //               reload();
            //               toast.success("Deleted");
            //             }
            //           }}
            //         >
            //           Delete
            //         </button>
            //       </div>
            //     );
            //   },
            // },
          ]}
        />
      </div>

      <ClientModal
        open={edit}
        setOpen={setEdit}
        reload={reload}
        update={item}
        customers={customers}
        setUpdate={setItem}
        customer
        groups={groups}
      />
    </div>
  );
};

import React from "react";
import { useNavigate } from "react-router-dom";
import Loading from "../components/Loading";
import useStore from "../utils/store";

const Logout = () => {
  const setUser = useStore((state) => state.setUser);
  const navigate = useNavigate();

  const logout = () => {
    setUser(null);
    navigate("/login");
  };

  React.useEffect(() => {
    logout();
  }, [navigate]);
  return <Loading>Logout</Loading>;
};

export default Logout;

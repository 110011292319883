import React from "react";
import Layout3 from "../../components/Layout/Layout3";
import CustomSelect from "../../components/Select";
import { ModalInput } from "../../components/Modal";
import { createPayment, getAllCustomers } from "../../utils/api";
import Loading from "../../components/Loading";
import { toast } from "react-toastify";
import useStore from "../../utils/store";
import Layout from "../../components/Layout/Layout";

const AdminPayment = () => {
  const user = useStore((state) => state.user);
  const initialPayment = {
    customerId: "",
    description: "",
    amount: "",
    paymentType: "",
    type: "admin",
    completedById: user.admin._id,
    date: new Date(),
  };
  const [loading, setLoading] = React.useState(false);
  const [newPayment, setNewPayment] = React.useState(initialPayment);
  const [customers, setCustomers] = React.useState([]);

  const getCustomers = async () => {
    setLoading(true);
    const [success, data] = await getAllCustomers();

    if (success) {
      setCustomers(data);
    }
    setLoading(false);
  };
  console.log(newPayment);
  const addPaymentHandler = async () => {
    setNewPayment({ ...newPayment, type: "admin" });
    setNewPayment({ ...newPayment, completedById: user.admin._id });
    if (
      newPayment.customerId === "" ||
      newPayment.amount === "" ||
      newPayment.paymentType === ""
    ) {
      toast.error("Please fill all the fields");
      return;
    }
    setLoading(true);
    const [success, data] = await createPayment({
      ...newPayment,
    });
    if (success) {
      setNewPayment(initialPayment);
      toast.success("Payment added successfully");
    } else {
      toast.error(data);
    }
    setLoading(false);
  };

  React.useEffect(() => {
    getCustomers();
  }, []);
  return (
    <Layout>
      {loading && <Loading />}

      <div className="flex md:items-center justify-center gap-3 md:flex-row flex-col bg-white py-10 px-10">
        <div className="flex flex-col gap-2">
          <div className="bg-secondary border-2 border-black px-2 py-1">
            Customer
          </div>
          <CustomSelect
            onChange={(e) => {
              setNewPayment({ ...newPayment, customerId: e.target.value });
            }}
            options={customers
              .filter((item) => item?.type === "Customer")
              .map((customer) => ({
                value: customer._id,
                label: `${customer.userName} (${customer?.group?.name})`,
              }))}
            value={newPayment.customerId}
          />
        </div>
        <div className="flex flex-col gap-2">
          <div className="bg-secondary border-2 border-black px-2 py-1">
            description
          </div>
          <ModalInput
            width={"w-full"}
            value={newPayment?.description}
            onChange={(e) => {
              setNewPayment({ ...newPayment, description: e.target.value });
            }}
          />
        </div>
        <div className="flex flex-col gap-2">
          <div className="bg-secondary border-2 border-black px-2 py-1">
            Payment
          </div>
          <CustomSelect
            onChange={(e) => {
              setNewPayment({ ...newPayment, paymentType: e.target.value });
            }}
            options={[
              { value: "Received", label: "Received" },
              { value: "Payment", label: "Payment" },
            ]}
            value={newPayment.paymentType}
          />
        </div>
        <div className="flex flex-col gap-2">
          <div className="bg-secondary border-2 border-black px-2 py-1">
            Amount
          </div>
          <ModalInput
            width={"w-full"}
            value={newPayment?.amount}
            onChange={(e) => {
              setNewPayment({ ...newPayment, amount: e.target.value });
            }}
            type={"number"}
          />
        </div>
        <div className="flex flex-col gap-2">
          <div className="bg-secondary border-2 border-black px-2 py-1">
            Date
          </div>
          <ModalInput
            width={"w-full"}
            value={newPayment?.date}
            onChange={(e) => {
              setNewPayment({ ...newPayment, date: e.target.value });
            }}
            type={"date"}
          />
        </div>

        <button
          onClick={addPaymentHandler}
          className="border-2 w-full md:w-auto border-black bg-primary text-white py-1 px-2 focus:outline-none"
        >
          Save
        </button>
      </div>
    </Layout>
  );
};

export default AdminPayment;

import { useEffect, useState } from "react";
import Head from "../Head";
import Sidebar from "./Sidebar";
import useStore from "../../utils/store";
import { useNavigate } from "react-router-dom";

const Layout = ({ children, onClick, text, title, reload }) => {
  const [open, setOpen] = useState(false);
  const user = useStore((state) => state.user);
  const navigate = useNavigate();
  useEffect(() => {
    if (!user || user?.type !== "Admin") {
      navigate("/login");
    }
  }, [user, navigate]);

  return (
    <div className="md:flex min-h-[100dvh]  bg-layoutbg">
      <Sidebar
        open={open}
        setOpen={setOpen}
        links={[
          {
            title: "Dashboard",
            path: "/admin",
          },
          {
            title: "Staff",
            path: "/admin/staff",
          },
          {
            title: " Client",
            path: "/admin/client",
          },
          {
            title: " Client ADD",
            path: "/admin/client/add",
          },
          {
            title: "Products",
            path: "/admin/products",
          },
          {
            title: "Customer ADD",
            path: "/admin/customer/add",
          },
          {
            title: "Customer",
            path: "/admin/customer",
          },
          {
            title: "City ADD",
            path: "/admin/group",
          },
          {
            title: "Dispatch ",
            path: "/admin/dispatch/complete",
          },
          {
            title: "Dispatch Completed",
            path: "/admin/dispatch",
          },
          {
            title: "Orders",
            path: "/admin/orders",
          },
          {
            title: "Payments",
            path: "/admin/payments",
          },
          {
            title: "Purchase",
            path: "/admin/purchase",
          },
          {
            title: "Product Price ADD",
            path: "/admin/product-price",
          },
          {
            title: "Invoices",
            path: "/admin/invoice"
          },
          {
            title: "Contact",
            path: "/admin/contact",
          },
          {
            title: "Logout",
            path: "/logout",
          },
        ]}
      />

      <div className="w-full md:overflow-y-auto ">
        <Head
          onClick={onClick}
          text={text}
          title={title}
          menu={() => setOpen(true)}
          reload={reload}
        />

        <div className="px-6 py-4  ">{children}</div>
      </div>
    </div>
  );
};

export default Layout;

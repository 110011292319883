import React from "react";
import { Button, Modal } from "@mui/material";
import useStore from "../../utils/store";
import { createPayment } from "../../utils/api";
import { toast } from "react-toastify";

const IsPayment = ({ defaultfuntion, canOpen, type, subClose, userType }) => {
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const user = useStore((state) => state.user);
  const addPaymentHandler = async (amount, cid) => {
    setLoading(true);
    const [success, data] = await createPayment({
      type: userType ?? "staff",
      completedById: userType === "admin" ? user.admin._id : user.staff._id,
      paymentType: type,
      amount: amount,
      customerId: cid,
    });
    if (success) {
      toast.success("Payment added successfully");
    } else {
      toast.error(data);
    }

    setLoading(false);
  };
  return (
    <div className="flex ">
      <Button
        onClick={() => {
          if (canOpen) setOpen(true);
        }}
        variant="contained"
      >
        Save
      </Button>
      <Modal
        open={open}
        onClose={() => {
          setOpen(false);
        }}
      >
        <div
          className="w-[95%] md:w-[20rem]  bg-[#fff685] border-2 border-black rounded-lg  py-10 px-6 flex flex-col gap-6
      top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 absolute
  "
        >
          <div className="flex items-center justify-between">
            <h2 className="text-2xl font-semibold">Is Payment Done?</h2>
            <button
              onClick={() => {
                setOpen(false);
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6 text-red-500 hover:text-red-700 cursor-pointer"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>
          </div>
          <div className="flex gap-8 mt-3 items-center justify-between">
            <Button
              variant="contained"
              color="primary"
              disabled={loading}
              onClick={async () => {
                setLoading(true);
                const data = await defaultfuntion();
                await addPaymentHandler(data?.totalAmount, data?.customer);
                setLoading(false);
                setOpen(false);
                subClose && subClose(false);
              }}
            >
              {loading ? "Loading..." : "Yes"}
            </Button>
            <Button
              variant="contained"
              color="error"
              disabled={loading}
              onClick={async () => {
                setLoading(true);
                const data = await defaultfuntion();
                setLoading(false);
                setOpen(false);
                subClose && subClose(false);
              }}
            >
              {loading ? "Loading..." : "No"}
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default IsPayment;

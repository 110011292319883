import React from "react";
import { Link } from "react-router-dom";
import Layout from "../../components/Layout/Layout";
import Loading from "../../components/Loading";
import Table from "../../components/Table";
import { deleteInvoice, getAllInvoices, getJson } from "../../utils/api";

import { Button } from "@mui/material";
import { toast } from "react-toastify";
import useStore from "../../utils/store";
import { PDFDownloadLink } from "@react-pdf/renderer";
import InvoiceConvertor from "./InvoiceConvertor";

const Invoices = () => {
  const [invoices, setInvoices] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [loading2, setLoading2] = React.useState(false);
  const startDate = useStore((state) => state.startDate);
  const endDate = useStore((state) => state.endDate);
  const getData = async () => {
    setLoading(true);
    const [success, data] = await getAllInvoices(startDate, endDate);
    if (success) {
      setInvoices(data);
    }
    setLoading(false);
  };

  const jsonFileDownload = async () => {
    // const json_data = {
    //   name: "Dedar",
    //   age: "14",
    //   address: "House #28",
    // };
    setLoading(true);
    const [success, json_data] = await getJson(startDate, endDate);
    console.log(json_data);
    if (success) {
      const fileName = `invoices${new Date().getTime()}.json`;
      const data = new Blob([JSON.stringify(json_data)], { type: "text/json" });
      const jsonURL = window.URL.createObjectURL(data);
      const link = document.createElement("a");
      document.body.appendChild(link);
      link.href = jsonURL;
      link.setAttribute("download", fileName);
      link.click();
      document.body.removeChild(link);
    }
    setLoading(false);
  };

  React.useEffect(() => {
    getData();
  }, [startDate, endDate]);
  return (
    <Layout>
      {loading && <Loading />}
      <Button onClick={jsonFileDownload} variant="contained">
        Download Json
      </Button>
      <Table
        columns={[
          {
            title: "INVOICE No.",
            dataIndex: "invoiceNumberString",
          },
          {
            title: "DATE",
            dataIndex: "invoiceDate",
            render: (date) =>
              new Date(date?.invoiceDate).toLocaleDateString("en-GB"),
          },
          {
            title: "CUSTOMER",
            dataIndex: "customer",
            render: (customer) => <>{customer?.customer?.userName}</>,
          },
          {
            title: "TOTAL",
            dataIndex: "grandTotal",
            render: (total) => <>{total?.grandTotal?.toFixed(2)}</>,
          },
          {
            title: "Download",
            render: (row) => (
              <div className="flex gap-2">
                <Link
                  to={`/admin/invoice/${row._id}`}
                  target="_blank"
                  className="text-black"
                >
                  View Invoice
                </Link>
              </div>
            ),
          },
          {
            title: "Action",
            render: (row) => (
              <Button
                variant="contained"
                color="error"
                onClick={async () => {
                  setLoading2(true);
                  const [success, data] = await deleteInvoice(row._id);
                  if (success) {
                    toast.success("Invoice Deleted Successfully");
                    getData();
                  }
                  setLoading2(false);
                }}
              >
                {loading2 ? "Deleting..." : "Delete"}
              </Button>
            ),
          },
        ]}
        data={invoices}
      />
    </Layout>
  );
};

export default Invoices;

import { useState } from "react";
import CustomerDetailsCom from "../../components/CustomerDetailsCom";
import Layout from "../../components/Layout/Layout";
const CustomerDetails = () => {
  const [open, setOpen] = useState(false);
  return (
    <Layout>
      <CustomerDetailsCom
        text={"Edit"}
        title={""}
        onClick={() => setOpen(true)}
        open={open}
        setOpen={setOpen}
      />
    </Layout>
  );
};

export default CustomerDetails;

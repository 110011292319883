import React, { useState } from "react";
import Layout from "../../components/Layout/Layout";
import {
  getAllCustomers,
  getProductData,
  getProductGstByCustomer,
} from "../../utils/api";

const AddProductGST = () => {
  const [loading, setLoading] = useState(false);
  const [text, setText] = useState(0);
  const [customer, setCustomer] = useState(null);
  const [rate, setRate] = useState(null);
  const [product, setProduct] = useState(null);
  const [customers, setCustomers] = useState([]);
  const [products, setProducts] = useState([]);

  const getAllCustomerHandler = async () => {
    setLoading(true);
    const [success, data] = await getAllCustomers();
    if (success) {
      setCustomers(data);
    }
    setLoading(false);
  };
  const getProductsData = async () => {
    setLoading(true);
    const [success, data] = await getProductData();
    if (success) {
      setProducts(data);
    }

    setLoading(false);
  };
  const [search, setSearch] = useState("");
  const getProductGSt = async (data) => {
    setLoading(true);

    const [success, data2] = await getProductGstByCustomer(data);
    if (success) {
      setRate(data2);
      if (data2) {
        setText(data2?.gst);
      } else {
        setText(0);
      }
    }

    setLoading(false);
  };

  React.useEffect(() => {
    getAllCustomerHandler();
    getProductsData();
  }, []);

  return (
    <Layout>
      <div className="grid md:grid-cols-3 gap-6">
        <div className="border p-3 rounded bg-white border-black">
          <h2 className="border-b pb-3">Client</h2>
          <div className="flex  gap-2 my-3">
            <input
              type="text"
              placeholder="Search"
              className="border border-black flex-1 w-full rounded p-2"
              value={search}
              onChange={(e) => {
                setSearch(e.target.value);
              }}
            />
          </div>
          <div className="flex flex-col gap-2 mt-3">
            {customers.filter(
              (item) =>
                item?.type === "Client" &&
                item?.userName?.toLowerCase().includes(search.toLowerCase())
            )?.length === 0 && <p className="text-center">No Client Found</p>}
            {customers
              .filter(
                (item) =>
                  item?.type === "Client" &&
                  item?.userName?.toLowerCase().includes(search.toLowerCase())
              )
              ?.map((item, index) => (
                <button
                  className={`flex gap-2  py-1
                ${
                  customer?.userName === item.userName
                    ? "bg-primary text-white px-2 rounded"
                    : "bg-white text-black"
                }
                `}
                  key={index}
                  onClick={() => {
                    setCustomer(item);
                    if (product) {
                      getProductGSt({
                        customerId: item?._id,
                        productId: product?._id,
                      });
                    }
                  }}
                >
                  <p>{item.userName}</p>
                </button>
              ))}
          </div>
        </div>
        <div className="border p-3 rounded bg-white border-black">
          <h2 className="border-b pb-3">Products</h2>
          <div className="flex flex-col gap-2 mt-3">
            {products?.map((item, index) => (
              <button
                className={`flex gap-2  py-1
                ${
                  product?.productName === item.productName
                    ? "bg-primary text-white px-2 rounded"
                    : "bg-white text-black"
                }
                    `}
                key={index}
                onClick={() => {
                  setProduct(item);
                  if (customer) {
                    getProductGSt({
                      customerId: customer?._id,
                      productId: item?._id,
                    });
                  }
                }}
              >
                <p>{item.productName}</p>
              </button>
            ))}
          </div>
        </div>
        <div className="border p-3 rounded bg-white border-black"></div>
      </div>
    </Layout>
  );
};

export default AddProductGST;

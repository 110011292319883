import { Button, Modal } from "@mui/material";
import { useState } from "react";
import { BiError } from "react-icons/bi";
import { Link, useNavigate } from "react-router-dom";
import logo from "../assets/logo.png";
import CustomInput from "../components/CustomInput";
import Loading from "../components/Loading";
import { login } from "../utils/api";
import useStore from "../utils/store";

const Login = () => {
  const [data, setData] = useState({});
  const setUser = useStore((state) => state.setUser);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const loginHandler = async () => {
    if (!data.userName || !data.password) {
      return;
    }
    setLoading(true);
    const [success, res] = await login(data);
    if (success) {
      if (res.type !== "Superadmin") {
        setUser(res);
      }
      if (res.type === "Admin") {
        navigate("/admin");
      }
      if (res.type === "Customer") {
        navigate("/client");
      }
      if (res.type === "MarketingStaff") {
        navigate("/staff/marketing");
      }
      if (res.type === "TradingStaff") {
        navigate("/staff/trading");
      }
      if (res.type === "TagStaff") {
        navigate("/staff/tag");
      }
      if (res.type === "StancilStaff") {
        navigate("/staff/stancil");
      }
      if (res.type === "OtherStaff") {
        navigate("/staff/other");
      }
      if (res.type === "Dispatch") {
        navigate("/staff/dispatch");
      }
      if (res.type === "Superadmin") {
        setError("Please login with correct user");
      }
    } else {
      setError(res?.response?.data ?? "Enter Valid Username and Password");
    }
    setLoading(false);
  };

  return (
    <div className="h-[100dvh] bg-gray-100 flex justify-center items-center">
      {loading && <Loading />}
      <div className="w-[20rem]  bg-white rounded-lg  py-10 px-5 flex flex-col gap-6">
        <img src={logo} alt="logo" className="w-5/6  mx-auto" />
        <div className="flex flex-col gap-5 ">
          <h1 className="text-xl font-semibold">Login</h1>
          <div className="flex flex-col gap-3 ">
            <CustomInput
              title="User Name"
              onChange={(e) => {
                setData((prev) => ({ ...prev, userName: e.target.value }));
              }}
            />
            <div className="flex flex-col gap-1">
              <CustomInput
                type={"password"}
                title="Password"
                onChange={(e) => {
                  setData((prev) => ({ ...prev, password: e.target.value }));
                }}
              />
            </div>
            <Link to="/change-password" className="text-sm text-gray-500">
              Change Password
            </Link>
          </div>
        </div>
        <Button
          disabled={loading}
          variant="contained"
          color="primary"
          onClick={loginHandler}
        >
          Login
        </Button>
      </div>
      <Modal open={error} onClose={() => setError(null)}>
        <div className="bg-white w-[20rem] mx-auto mt-20 p-5 rounded-lg">
          <BiError className="text-red-500 text-4xl" />
          <h1 className="text-xl font-semibold">Error</h1>
          <p className="text-red-500">{error?.message}</p>
        </div>
      </Modal>
    </div>
  );
};

export default Login;

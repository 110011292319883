import React from "react";
import { toast } from "react-toastify";
import Layout4 from "../../components/Layout/Layout4";
import Loading from "../../components/Loading";
import Table from "../../components/Table";
import { completeOrder, getStancilStaff } from "../../utils/api";
import useStore from "../../utils/store";
import { formatDate } from "../../utils/formatData";

const Stancil = () => {
  const [dash, setDash] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const user = useStore((state) => state.user);
  const getData = async () => {
    setLoading(true);
    const [success, data] = await getStancilStaff();
    if (success) {
      setDash(data);
    }
    setLoading(false);
  };
  React.useEffect(() => {
    getData();
  }, []);
  return (
    <Layout4 type={"StancilStaff"} title={user?.staff?.userName}>
      {loading && <Loading />}
      <Table
        data={dash}
        type={"stancil"}
        columns={[
          {
            title: "Date",
            render: (row) => formatDate(row?.createdAt),
          },
          {
            title: "User",
            render: (row) => (
              <div>
                {row.createdByName}, {row?.completedByName}
              </div>
            ),
          },
          {
            title: "description",
            render: (row) => row.description,
          },
          {
            title: "Location",
            render: (record) => record?.customer?.group?.name,
          },
          {
            title: "Items",
            render: (row) => row.product.productName,
          },
          {
            title: "Qnt.",
            render: (row) => row.quantity,
          },
          {
            title: "Customer",
            render: (row) => row.customer.userName,
          },
          {
            title: "Image",
            render: (row) => (
              <a href={row?.customer?.image} target="_blank" rel="noreferrer">
                {row?.customer?.image ? "View" : ""}
              </a>
            ),
          },
          {
            title: "PDF",
            render: (row) => (
              <button
                className="bg-green-500  px-2 py-1 "
                onClick={async () => {
                  setLoading(true);
                  const [success, data] = await completeOrder(row._id, {
                    type: "staff",
                    completedById: user.staff._id,
                  });
                  if (success) {
                    toast.success("Order Completed");
                    getData();
                  } else {
                    toast.error(data);
                  }
                  setLoading(false);
                }}
              >
                Complete
              </button>
            ),
          },
        ]}
      />
    </Layout4>
  );
};

export default Stancil;

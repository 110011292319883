import React from "react";
import { toast } from "react-toastify";
import Layout from "../../components/Layout/Layout";
import Loading from "../../components/Loading";
import Table from "../../components/Table";
import { deleteContact, getAllContacts } from "../../utils/api";

const Contact = () => {
  const [dash, setDash] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const getData = async () => {
    setLoading(true);
    const [success, data] = await getAllContacts();
    if (success) {
      setDash(data);
    }
    setLoading(false);
  };

  React.useEffect(() => {
    getData();
  }, []);

  return (
    <Layout>
      {loading && <Loading />}
      <Table
        type="dispatch"
        data={dash}
        columns={[
          {
            title: "Name",
            dataIndex: "name",
          },
          {
            title: "Email",
            dataIndex: "email",
          },
          {
            title: "Phone",
            dataIndex: "phoneNumber",
          },
          {
            title: "Subject",
            dataIndex: "subject",
          },
          {
            title: "Message",
            dataIndex: "message",
          },
          {
            title: "Actions",
            render: (row) => (
              <button
                className="bg-red-500  px-2 py-1 "
                onClick={async () => {
                  setLoading(true);
                  const [success, data] = await deleteContact(row._id);
                  if (success) {
                    toast.success("Deleted Completed");
                    getData();
                  } else {
                    toast.error(data);
                  }
                  setLoading(false);
                }}
              >
                Delete
              </button>
            ),
          },
        ]}
      />
    </Layout>
  );
};

export default Contact;

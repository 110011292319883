import CustomModal from "./Modal";
import { useState } from "react";
import { showbanner } from "../utils/api";
import { Button, CircularProgress } from "@mui/material";
import IsPayment from "./modals/IsPayment";
import { formatDate } from "../utils/formatData";

const CreateOrder = ({ id, qnt, onClick, loading2 }) => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});
  const showData = async () => {
    setLoading(true);
    const [success, data] = await showbanner(id);
    if (success) {
      setData(data);
    }
    setLoading(false);
  };

  return (
    <div className="flex ">
      <Button
        variant="contained"
        color="primary"
        onClick={() => {
          if (!id) return;
          if (+qnt <= 0) return;
          setOpen(true);
          showData();
        }}
      >
        {loading2 ? "Loading..." : "Save"}
      </Button>
      <CustomModal
        color={
          parseInt(data?.totalOrder) -
            ((data?.complete ? parseInt(data?.complete) : 0) + parseInt(qnt)) <
          0
            ? true
            : false
        }
        loading={loading2}
        open={open}
        setOpen={setOpen}
        onClick={async () => {
          await onClick();
          setOpen(false);
        }}
      >
        {loading ? (
          <CircularProgress />
        ) : data?.isWagon &&
          parseInt(data?.totalOrder) -
            ((data?.complete ? parseInt(data?.complete) : 0) + parseInt(qnt)) <
            4000 ? (
          <>
            <div className="flex justify-between text-green-500 font-bold">
              <p>Total Balances</p>
              <p>{data?.totalBalance?.toFixed(2)}</p>
            </div>
            <div className="flex justify-between ">
              <p>Total Order</p>
              <p>{data?.totalOrder}</p>
            </div>
            <div className="flex justify-between ">
              <p>Complete</p>
              <p>{data?.complete}</p>
            </div>
            <div className="flex justify-between ">
              <p>New Order</p>
              <p>{qnt}</p>
            </div>
            <div className="flex justify-between font-bold">
              <p
                className={`
                ${
                  parseInt(data?.totalOrder) -
                    ((data?.complete ? parseInt(data?.complete) : 0) +
                      parseInt(qnt)) <
                  0
                    ? "text-pink-500"
                    : "text-green-500"
                }
                `}
              >
                {parseInt(data?.totalOrder) -
                  ((data?.complete ? parseInt(data?.complete) : 0) +
                    parseInt(qnt)) <
                0
                  ? "Extra Quantity"
                  : " Balance"}
              </p>
              <p
                className={`
                ${
                  parseInt(data?.totalOrder) -
                    ((data?.complete ? parseInt(data?.complete) : 0) +
                      parseInt(qnt)) <
                  0
                    ? "text-pink-500"
                    : "text-green-500"
                }
                `}
              >
                {parseInt(data?.totalOrder) -
                  ((data?.complete ? parseInt(data?.complete) : 0) +
                    parseInt(qnt))}
              </p>
            </div>
            <div className="flex justify-between font-bold">
              <p>Total Ready</p>
              <p>{parseInt(data?.complete) + parseInt(qnt)}</p>
            </div>
            <div className="flex justify-between">
              <p>
                Last Completed{" "}
                {data?.lastOrder && formatDate(data?.lastOrder?.createdAt)}
              </p>
              <p>{data?.lastOrder?.quantity}</p>
            </div>

            {data.isPendingOrder && (
              <div className="flex justify-between text-red-500 font-bold">
                <p>Pending ({formatDate(data?.pendingOrder?.createdAt)})</p>
                <p>{data?.pendingOrder?.quantity}</p>
              </div>
            )}
            <div className="flex justify-between bg-white px-5 py-2 rounded-lg font-semibold mr-auto">
              <p>
                {data?.lastOrder &&
                  //current date - last order date and show days ago
                  Math.floor(
                    (new Date() - new Date(data?.lastOrder?.createdAt)) /
                      (1000 * 60 * 60 * 24)
                  ) + " days ago"}
              </p>
            </div>
          </>
        ) : (
          <>
            <div className="flex justify-between text-green-500 font-bold">
              <p>
                Last Completed (
                {data?.lastOrder && formatDate(data?.lastOrder?.createdAt)})
              </p>
              <p>{data?.lastOrder?.quantity}</p>
            </div>
            <div className="flex justify-between text-green-500 font-bold">
              <p>Total Balances</p>
              <p>{data?.totalBalance?.toFixed(2)}</p>
            </div>
            {data.isPendingOrder && (
              <div className="flex justify-between text-red-500 font-bold">
                <p>
                  Pending (
                  {data?.pendingOrder &&
                    formatDate(data?.pendingOrder?.createdAt)}
                  )
                </p>
                <p>{data?.pendingOrder?.quantity}</p>
              </div>
            )}
            <div className="flex justify-between bg-white px-5 py-2 rounded-lg font-semibold mr-auto">
              <p>
                {data?.lastOrder &&
                  //current date - last order date and show days ago
                  Math.floor(
                    (new Date() - new Date(data?.lastOrder?.createdAt)) /
                      (1000 * 60 * 60 * 24)
                  ) + " days ago"}
              </p>
            </div>
          </>
        )}
      </CustomModal>
    </div>
  );
};

export default CreateOrder;

import { G, Path, Svg, View } from "@react-pdf/renderer";
import React from "react";

const Logo = () => {
  return (
    <View>
      <Svg
        version={1.0}
        xmlns="http://www.w3.org/2000/svg"
        width="100.000000pt"
        height="100.000000pt"
        viewBox="0 0 137.000000 144.000000"
        preserveAspectRatio="xMidYMid meet"
        style={{ width: 80, height: 80 }}
      >
        <G
          transform="translate(0.000000,144.000000) scale(0.100000,-0.100000)"
          fill="#000000"
          stroke="none"
        >
          <Path
            d="M540 1391 c0 -4 -28 -11 -62 -15 -35 -3 -64 -11 -66 -16 -2 -6 -12
-10 -23 -10 -10 0 -19 -4 -19 -10 0 -5 -5 -10 -11 -10 -6 0 -24 -13 -39 -30
-15 -16 -33 -30 -40 -30 -12 0 -90 -74 -90 -85 0 -6 -11 -20 -37 -50 -7 -7
-13 -21 -13 -29 0 -9 -4 -16 -10 -16 -5 0 -10 -7 -10 -15 0 -8 -4 -15 -10 -15
-5 0 -10 -13 -10 -30 0 -16 -4 -30 -10 -30 -5 0 -10 -11 -10 -24 0 -13 -6 -26
-12 -29 -18 -5 -18 -339 0 -345 6 -2 12 -15 12 -28 0 -13 5 -24 10 -24 6 0 10
-13 10 -30 0 -16 5 -30 10 -30 6 0 10 -5 10 -11 0 -7 8 -19 18 -28 11 -9 22
-28 25 -43 4 -15 16 -30 27 -33 11 -3 20 -13 20 -21 0 -14 43 -64 55 -64 6 0
20 -11 50 -37 7 -7 21 -13 29 -13 9 0 16 -4 16 -10 0 -5 7 -10 15 -10 8 0 15
-4 15 -10 0 -5 9 -10 20 -10 11 0 20 -4 20 -10 0 -5 11 -10 24 -10 13 0 26 -6
29 -12 5 -18 319 -18 325 0 2 6 12 12 23 12 10 0 19 5 19 10 0 6 10 10 23 10
12 0 29 9 37 20 8 11 22 20 31 20 9 0 19 7 23 15 3 8 13 15 21 15 18 0 95 75
95 92 0 5 14 23 30 38 17 15 30 35 30 44 0 9 4 16 10 16 5 0 12 16 16 35 3 19
10 35 15 35 5 0 9 11 9 25 0 14 5 25 10 25 6 0 10 18 10 40 0 22 5 40 10 40 6
0 10 45 10 115 0 70 -4 115 -10 115 -5 0 -10 18 -10 40 0 22 -4 40 -10 40 -5
0 -10 11 -10 25 0 14 -4 25 -9 25 -5 0 -12 16 -15 35 -4 19 -11 35 -16 35 -6
0 -10 8 -10 18 0 10 -9 24 -20 32 -11 8 -20 18 -20 23 0 19 -108 127 -127 127
-5 0 -15 9 -23 20 -8 11 -22 20 -32 20 -10 0 -18 5 -18 10 0 6 -7 10 -15 10
-8 0 -15 4 -15 9 0 5 -29 13 -65 16 -36 4 -65 11 -65 16 0 5 -45 9 -100 9 -55
0 -100 -4 -100 -9z m468 -230 c42 -36 20 -91 -37 -91 -28 0 -31 3 -31 30 0 27
-3 30 -30 30 -19 0 -40 -9 -55 -25 -29 -28 -31 -35 -9 -35 18 0 94 -73 94 -90
0 -6 5 -10 10 -10 6 0 10 -28 10 -65 0 -37 -4 -65 -10 -65 -5 0 -10 -8 -10
-18 0 -10 -10 -24 -21 -32 -12 -8 -27 -21 -35 -27 -7 -7 -17 -13 -23 -13 -6 0
-11 -4 -11 -10 0 -15 -167 -13 -173 3 -3 6 -6 2 -6 -10 -1 -13 -5 -23 -11 -23
-5 0 -10 -9 -10 -20 0 -11 -4 -20 -10 -20 -5 0 -10 -7 -10 -15 0 -8 -4 -15
-10 -15 -5 0 -10 -7 -10 -15 0 -9 -20 -35 -45 -59 -51 -50 -53 -56 -25 -56 11
0 20 -4 20 -10 0 -5 25 -10 55 -10 30 0 55 -4 55 -9 0 -12 180 -24 230 -16 30
5 40 11 40 26 0 10 4 19 9 19 5 0 11 14 15 30 7 36 46 44 46 10 0 -11 -4 -20
-10 -20 -5 0 -10 -13 -10 -30 0 -16 -4 -30 -10 -30 -5 0 -10 -7 -10 -15 0 -8
-16 -31 -37 -51 l-36 -37 -96 7 c-53 4 -97 12 -99 17 -2 5 -15 9 -28 9 -13 0
-24 5 -24 10 0 6 -13 10 -30 10 -16 0 -30 5 -30 10 0 6 -11 10 -25 10 -14 0
-25 5 -25 10 0 6 -27 10 -61 10 -51 0 -65 -4 -80 -22 -11 -11 -22 -18 -27 -13
-4 4 -1 10 6 12 20 7 14 23 -9 23 -19 0 -49 -21 -49 -34 0 -3 9 -6 20 -6 11 0
20 -4 20 -10 0 -5 -13 -10 -30 -10 -27 0 -30 3 -30 30 0 26 5 31 30 37 17 3
30 9 30 14 0 5 11 9 25 9 14 0 25 5 25 10 0 6 9 10 20 10 20 0 70 41 70 57 0
5 9 15 20 23 11 8 20 22 20 32 0 10 4 18 9 18 5 0 13 25 16 55 4 30 11 55 16
55 5 0 9 16 9 35 0 19 5 35 10 35 6 0 10 11 10 25 0 14 5 25 10 25 6 0 10 9
10 20 0 11 5 20 10 20 6 0 10 9 10 20 0 11 4 20 9 20 5 0 12 11 15 25 4 14 11
25 16 25 6 0 10 9 10 20 0 11 5 20 10 20 6 0 10 7 10 16 0 15 -10 16 -77 11
-43 -3 -79 -11 -81 -16 -2 -6 -12 -11 -23 -11 -10 0 -19 -4 -19 -10 0 -5 -8
-10 -18 -10 -10 0 -24 -9 -32 -20 -8 -11 -20 -20 -26 -20 -20 0 -70 -54 -78
-82 -3 -16 -11 -28 -16 -28 -6 0 -10 -7 -10 -15 0 -8 -4 -15 -10 -15 -5 0 -10
-16 -10 -35 0 -19 -4 -35 -10 -35 -5 0 -10 -18 -10 -40 0 -32 -4 -40 -19 -40
-15 0 -20 10 -25 51 -8 58 2 169 15 169 5 0 9 8 9 18 0 10 10 24 21 32 27 19
60 51 78 78 8 11 29 23 48 27 18 3 33 10 33 15 0 6 14 10 30 10 17 0 30 5 30
10 0 6 47 10 120 10 81 0 120 -4 120 -11 0 -6 13 3 29 20 16 17 36 31 45 31 9
0 16 4 16 9 0 26 129 28 158 2z"
          />
          <Path
            d="M778 1018 c-10 -12 -18 -28 -18 -35 0 -7 -4 -13 -10 -13 -5 0 -10
-13 -10 -30 0 -16 -4 -30 -10 -30 -5 0 -10 -9 -10 -20 0 -11 -4 -20 -10 -20
-5 0 -10 -15 -10 -34 0 -19 -6 -36 -12 -39 -22 -8 -2 -17 48 -23 45 -6 52 -4
82 24 17 16 32 37 32 46 0 9 5 16 10 16 6 0 10 27 10 60 0 33 -4 60 -10 60 -5
0 -10 7 -10 16 0 19 -24 44 -42 44 -7 0 -21 -10 -30 -22z"
          />
        </G>
      </Svg>
    </View>
  );
};

export default Logo;

import { Button } from "@mui/material";
import { useEffect, useState } from "react";
import { RiMenu2Line } from "react-icons/ri";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { cleanCustomer, systemValues, updateSystemValues } from "../utils/api";
import Confirm2 from "./modals/Confirm2";
import useStore from "../utils/store";

const Head = ({ text, onClick, title, menu, reload }) => {
  const location = useLocation();
  const [open, setOpen] = useState(false);
  const user = useStore((state) => state.user);
  const startDate = useStore((state) => state.startDate);
  const endDate = useStore((state) => state.endDate);

  const getSystemValues = async () => {
    const [success, res] = await systemValues();
    console.log(res, "head");
    if (success) {
      setStartDate(res?.startDate?.split("T")[0]);
      setEndDate(res?.endDate?.split("T")[0]);
    }
  };

  const updateSystem = async (data) => {
    const [success, res] = await updateSystemValues(data);
    if (success) {
    } else {
      toast.error("System Values Not Updated");
    }
  };

  const setStartDate = useStore((state) => state.setStartDate);
  const setEndDate = useStore((state) => state.setEndDate);

  useEffect(() => {
    getSystemValues();
  }, []);
  console.log(startDate, endDate, "head");
  return (
    <div className="flex justify-between px-6 flex-wrap gap-6 pb-3 pt-8 text-primary font-semibold text-xl sticky top-0 bg-layoutbg">
      <div className="flex items-center gap-4  ">
        <button onClick={menu} className=" bg-primary rounded-full p-2">
          <RiMenu2Line className="text-2xl text-white" />
        </button>
        {title ? title : "Admin"}
      </div>
      {text && (
        <Button variant="contained" color="primary" onClick={onClick}>
          {text}
        </Button>
      )}
      <div className="flex gap-6 flex-wrap">
        {user?.type === "Admin" && (
          <div className="hidden md:flex gap-4 items-center">
            <input
              type="date"
              value={startDate}
              onChange={async (e) => {
                setStartDate(e.target.value);
                await updateSystem({ startDate: e.target.value });
                window.location.reload();
              }}
              placeholder="Start Date"
              className="border-black text-sm py-2 px-3 border-2"
              max={endDate}
            />
            <p className="text-primary text-base">to</p>
            <input
              type="date"
              value={endDate}
              min={startDate}
              onChange={async (e) => {
                setEndDate(e.target.value);
                await updateSystem({ endDate: e.target.value });
                window.location.reload();
              }}
              placeholder="End Date"
              className="border-black text-sm py-2 px-3 border-2"
            />
          </div>
        )}
        {location.pathname === "/admin" && (
          <>
            <Button
              variant="contained"
              color="error"
              onClick={() => setOpen(true)}
            >
              Reset
            </Button>
            {open && (
              <Confirm2
                open={open}
                setOpen={setOpen}
                onClick={async () => {
                  const [success, res] = await cleanCustomer();
                  if (success) {
                    toast.success(res);
                    setOpen(false);
                    reload();
                  } else {
                    toast.error(res);
                  }
                }}
              />
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default Head;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `table tbody tr td {
  border-right-width: 1px;
  border-top-width: 1px;
  border-bottom-width: 0px;
  border-left-width: 0px;
  border-style: solid;
  border-color: black;
}

.invoice_main_container{
  background: rgb(204, 204, 204);
}

page {
  background: white;
  display: block;
  margin: 0 auto;
  margin-bottom: 0.5cm;
  box-shadow: 0 0 0.5cm rgba(0, 0, 0, 0.5);
}

page[size="A4"] {
  width: 21cm;
  height: 29.7cm;
}

page[size="A4"][layout="portrait"] {
  width: 29.7cm;
  height: 21cm;
}

@media print {

  body,
  page {
    margin: 0;
    box-shadow: 0;
  }
}`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,uBAAuB;EACvB,qBAAqB;EACrB,wBAAwB;EACxB,sBAAsB;EACtB,mBAAmB;EACnB,mBAAmB;AACrB;;AAEA;EACE,8BAA8B;AAChC;;AAEA;EACE,iBAAiB;EACjB,cAAc;EACd,cAAc;EACd,oBAAoB;EACpB,wCAAwC;AAC1C;;AAEA;EACE,WAAW;EACX,cAAc;AAChB;;AAEA;EACE,aAAa;EACb,YAAY;AACd;;AAEA;;EAEE;;IAEE,SAAS;IACT,aAAa;EACf;AACF","sourcesContent":["table tbody tr td {\n  border-right-width: 1px;\n  border-top-width: 1px;\n  border-bottom-width: 0px;\n  border-left-width: 0px;\n  border-style: solid;\n  border-color: black;\n}\n\n.invoice_main_container{\n  background: rgb(204, 204, 204);\n}\n\npage {\n  background: white;\n  display: block;\n  margin: 0 auto;\n  margin-bottom: 0.5cm;\n  box-shadow: 0 0 0.5cm rgba(0, 0, 0, 0.5);\n}\n\npage[size=\"A4\"] {\n  width: 21cm;\n  height: 29.7cm;\n}\n\npage[size=\"A4\"][layout=\"portrait\"] {\n  width: 29.7cm;\n  height: 21cm;\n}\n\n@media print {\n\n  body,\n  page {\n    margin: 0;\n    box-shadow: 0;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import React, { useState } from "react";
import Dash from "../../components/Dash";

import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import Layout from "../../components/Layout/Layout";
import Loading from "../../components/Loading";
import Search from "../../components/Search";
import Table from "../../components/Table";
import ProductModal from "../../components/modals/ProductModal";
import {
  deleteProduct,
  getProductData,
  getProuductById,
} from "../../utils/api";
import useStore from "../../utils/store";

const Products = () => {
  const [open, setOpen] = useState(false);
  const startDate = useStore((state) => state.startDate);
  const endDate = useStore((state) => state.endDate);
  const [products, setProducts] = useState([]);
  const [product, setProduct] = useState(null);
  const [update, setUpdate] = useState(false);
  const [loading, setLoading] = useState(false);
  const [productDataList, setProductDataList] = useState([]); // [
  const getProductsData = async () => {
    setLoading(true);
    const [success, data] = await getProductData();
    if (success) {
      setProducts(data);
    }
    setProduct(null);
    setLoading(false);
  };
  const getProuductByIdHandler = async (id) => {
    setLoading(true);
    const [success, data] = await getProuductById(id, startDate, endDate);
    if (success) {
      setProduct(data);
      if (data?.product?.productType === "Purchase") {
        setProductDataList(data?.purchaseData?.reverse());
      } else {
        setProductDataList(data?.salesData?.reverse());
      }
    }
    setLoading(false);
  };
  React.useEffect(() => {
    getProductsData();
  }, []);

  return (
    <Layout text="Add New " onClick={() => setOpen(true)}>
      {loading && <Loading />}
      <ProductModal open={open} setOpen={setOpen} reload={getProductsData} />
      <ProductModal
        open={update}
        setOpen={setUpdate}
        reload={getProductsData}
        update
        data={product?.product}
        setProduct={setProduct}
      />
      <div className="flex flex-col md:flex-row  gap-2 ">
        <Search
          active={product?.product?._id}
          data={products.map((product) => {
            return {
              name: product.productName,
              _id: product._id,
            };
          })}
          item={(item) => {
            getProuductByIdHandler(item._id);
          }}
        />
        {product && (
          <div className="flex flex-col flex-1 gap-6 w-9/12">
            <div className="flex gap-2 flex-wrap  flex-col md:flex-row">
              <Dash title={"Sale"} value={product?.sales} />
              <Dash title={"PURCHASE"} value={product?.purchase.toFixed(2)} />
              <Dash title={"PROFIT"} value={product?.profit.toFixed(2)} />
            </div>
            <Table
              columns={[
                {
                  title: "ITEMS",
                  dataIndex: "productName",
                },
                {
                  title: "TYPE",
                  dataIndex: "productType",
                },
                {
                  title: "TAX",
                  dataIndex: "tax",
                },
                {
                  title: "GROUP",
                  dataIndex: "group",
                },
                {
                  title: "RATE",
                  dataIndex: "rate",
                },
                {
                  title: "HSN",
                  dataIndex: "hsn",
                },
                {
                  title: "ACTION",
                  render: (product) => (
                    <div className="flex gap-2">
                      <button
                        onClick={() => {
                          setUpdate(true);
                        }}
                        className="bg-yellow-500 px-2 py-1 mr-3 border border-black"
                      >
                        Edit
                      </button>
                      <button
                        className="bg-red-500 px-2 py-1  border border-black"
                        onClick={async () => {
                          setLoading(true);
                          const [success, data] = await deleteProduct(
                            product?._id
                          );
                          if (success) {
                            setProduct(null);
                            getProductsData();
                            toast.success("Product Deleted");
                          }
                          setLoading(false);
                        }}
                      >
                        Delete
                      </button>
                    </div>
                  ),
                },
              ]}
              data={[product.product]}
            />
            <div className="ml-auto">
              <Link
                to={`/admin/pdf/products/${product?.product?._id}`}
                target="_blank"
                className="bg-red-500 px-3 py-1 border border-black"
              >
                PDF
              </Link>
            </div>
            <Table
              columns={[
                {
                  title: "DATE",
                  dataIndex: "createdAt",
                  render: (date) =>
                    new Date(date?.createdAt).toLocaleDateString("en-GB"),
                },
                {
                  title: "CUSTOMER",
                  dataIndex: "customer",
                  render: (customer) => <>{customer?.customer?.userName}</>,
                },
                {
                  title: "DESCRIPTION",
                  dataIndex: "description",
                },
                {
                  title: "GROUP",
                  dataIndex: "group",
                },
                {
                  title: "Qnt",
                  dataIndex: "quantity",
                },
                {
                  title: "RATE",
                  dataIndex: "rate",
                },
                {
                  title: "GST",
                  dataIndex: "gst",
                },
                {
                  title: "TOTAL",
                  dataIndex: "totalAmount",
                  render: (total) => <>{total?.totalAmount?.toFixed(2)}</>,
                },
                {
                  title: "USER",
                  dataIndex: "createdByName",
                  render: (user) => (
                    <>
                      {user?.createdByName}, {user?.completedByName}
                    </>
                  ),
                },

                {
                  title: "Image",
                  render: (row) => (
                    <a
                      href={row?.customer?.image}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {row?.customer?.image ? "View" : ""}
                    </a>
                  ),
                },
                {
                  title: "STATUS",
                  dataIndex: "status",
                },
              ]}
              data={productDataList}
            />
          </div>
        )}
      </div>
    </Layout>
  );
};

export default Products;

import { Text, View, StyleSheet } from "@react-pdf/renderer";
import React from "react";

const styles = StyleSheet.create({
  headingTab: {
    flex: 1.7,
    borderBottom: "solid",
    borderBottomWidth: 1,
    fontSize: "10px",
    padding: 3,
    fontWeight: "bold",
    borderRight: "solid",
    borderRightWidth: 1,
    textAlign: "left",
  },
  headingTabSmall: {
    flex: 0.5,
    borderBottom: "solid",
    borderBottomWidth: 1,
    fontSize: "10px",
    padding: 3,
    fontWeight: "bold",
    borderRight: "solid",
    borderRightWidth: 1,
    textAlign: "right",
  },
  headingTabVerySmall: {
    flex: 0.3,
    borderBottom: "solid",
    borderBottomWidth: 1,
    fontSize: "10px",
    padding: 3,
    fontWeight: "bold",
    borderRight: "solid",
    borderRightWidth: 1,
    textAlign: "right",
  },
  headingTabSmallLast: {
    flex: 0.5,
    borderBottom: "solid",
    borderBottomWidth: 1,
    fontSize: "10px",
    padding: 3,
    fontWeight: "bold",
    textAlign: "right",
  },
  bodyTabSmall: {
    flex: 0.5,
    borderBottom: "solid",
    borderBottomWidth: 1,
    fontSize: "10px",
    padding: 3,
    borderRight: "solid",
    borderRightWidth: 1,
    textAlign: "right",
    fontWeight: "semibold",
  },
  bodyTabSmallR: {
    flex: 0.5,
    borderBottom: "solid",
    borderBottomWidth: 1,
    fontSize: "10px",
    padding: 3,
    borderRight: "solid",
    borderRightWidth: 1,
    textAlign: "right",
    fontWeight: "bold",
  },
  bodyTabVerySmallR: {
    flex: 0.3,
    borderBottom: "solid",
    borderBottomWidth: 1,
    fontSize: "10px",
    padding: 3,
    borderRight: "solid",
    borderRightWidth: 1,
    textAlign: "right",
    fontWeight: "bold",
  },
  bodyTabVerySmall: {
    flex: 0.3,
    borderBottom: "solid",
    borderBottomWidth: 1,
    fontSize: "10px",
    padding: 3,
    borderRight: "solid",
    borderRightWidth: 1,
    textAlign: "right",
    fontWeight: "semibold",
  },
  bodyTabSmallNBB: {
    flex: 0.5,

    fontSize: "10px",
    padding: 3,
    borderRight: "solid",
    borderRightWidth: 1,
    fontWeight: "bold",
    textAlign: "right",
  },
  bodyTabVerySmallNBB: {
    flex: 0.3,

    fontSize: "10px",
    padding: 3,
    borderRight: "solid",
    borderRightWidth: 1,
    fontWeight: "bold",
  },
  bodyTabSmallNBBR: {
    flex: 0.5,

    fontSize: "10px",
    padding: 3,

    fontWeight: "bold",
  },
  bodyTabSmallNB: {
    flex: 0.5,
    borderBottom: "solid",
    borderBottomWidth: 1,
    fontSize: "10px",
    padding: 3,
    fontWeight: "bold",
    fontStyle: "italic",
  },
  bodyTabVerySmallNB: {
    flex: 0.3,
    borderBottom: "solid",
    borderBottomWidth: 1,
    fontSize: "10px",
    padding: 3,
    fontWeight: "bold",
    fontStyle: "italic",
  },
  bodyTabSmallBNB: {
    flex: 0.5,

    fontSize: "10px",
    padding: 3,
    fontWeight: "bold",
    fontStyle: "italic",
    textAlign: "right",
  },
  bodyTabVerySmallBNB: {
    flex: 0.3,

    fontSize: "10px",
    padding: 3,
    fontWeight: "bold",
    fontStyle: "italic",
  },
  bodyTabWidth: {
    flex: 0.5,
    borderBottom: "solid",
    borderBottomWidth: 1,
    fontSize: "10px",
    padding: 3,
  },
  bodyTabSmallLast: {
    flex: 0.5,
    borderBottom: "solid",
    borderBottomWidth: 1,
    fontSize: "10px",
    padding: 3,
    textAlign: "right",
    fontWeight: "semibold",
  },
  bodyTabSmallLastNB: {
    flex: 0.5,

    fontSize: "10px",
    padding: 3,
    fontWeight: "semibold",
    textAlign: "right",
  },
  bodyTab: {
    flex: 1.7,
    borderBottom: "solid",
    borderBottomWidth: 1,
    fontSize: "10px",
    padding: 3,
    borderRight: "solid",
    borderRightWidth: 1,
    textAlign: "left",
    fontWeight: "semibold",
  },
  bodyTabNB: {
    flex: 1.7,
    borderBottom: "solid",
    borderBottomWidth: 1,
    fontSize: "10px",
    padding: 3,
    fontWeight: "bold",
    fontStyle: "italic",
  },
  bodyTaBbNB: {
    flex: 1.7,

    fontSize: "10px",
    padding: 3,
    fontWeight: "bold",
    fontStyle: "italic",
  },
  bodyTaBbNBR: {
    flex: 1.7,

    fontSize: "10px",
    padding: 3,
    fontWeight: "bold",

    borderRight: "solid",
    borderRightWidth: 1,
    textAlign: "right",
  },
  VerybodyTaBbNB: {
    flex: 0.3,

    fontSize: "10px",
    padding: 3,
    fontWeight: "bold",
    fontStyle: "italic",
  },
});

const Table = ({ invoice }) => {
  return (
    <View
      style={{
        flex: 1,
      }}
    >
      <View
        style={{
          display: "flex",
          flexDirection: "row",
        }}
      >
        <Text style={styles.headingTabVerySmall}>S.No.</Text>
        <Text style={styles.headingTab}>Description of Goods</Text>
        <Text style={styles.headingTabSmall}>HSN/SAC code</Text>
        <Text style={styles.headingTabSmall}>Quantity</Text>
        <Text style={styles.headingTabVerySmall}>Unit</Text>
        <Text style={styles.headingTabVerySmall}>Price</Text>
        <Text style={styles.headingTabSmallLast}>Amount</Text>
      </View>
      {invoice?.products?.map((item, index) => (
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            flex: 1,
          }}
          key={index}
        >
          <Text style={styles.bodyTabVerySmall}>{index + 1}.</Text>
          <Text style={styles.bodyTab}>{item?.description}</Text>
          <Text style={styles.bodyTabSmall}>{item?.hsn}</Text>
          <Text style={styles.bodyTabSmall}>{item?.quantity}</Text>
          <Text style={styles.headingTabVerySmall}>{item?.unit}</Text>
          <Text style={styles.headingTabVerySmall}>{item?.rate}</Text>
          <Text
            style={
              index === invoice?.products?.length - 1
                ? styles?.bodyTabSmallLast
                : styles?.bodyTabSmall
            }
          >
            {item?.amount?.toFixed(2)}
          </Text>
        </View>
      ))}
      <View
        style={{
          display: "flex",
          flexDirection: "row",
        }}
      >
        <Text style={styles.bodyTabVerySmallBNB}></Text>
        <Text style={styles.bodyTaBbNB}></Text>
        <Text style={styles.bodyTabSmallBNB}></Text>
        <Text style={styles.bodyTabSmallBNB}></Text>
        <Text style={styles.bodyTabVerySmallBNB}></Text>
        <Text style={styles.bodyTabVerySmallNBB}></Text>
        <Text style={styles.bodyTabSmallLastNB}>
          {" "}
          {invoice?.preGstTotal?.toFixed(2)}
        </Text>
      </View>
      <View
        style={{
          display: "flex",
          flexDirection: "row",
        }}
      >
        <Text style={styles.bodyTabVerySmallBNB}></Text>
        <Text style={styles.bodyTabVerySmallBNB}></Text>
        <Text style={styles.bodyTaBbNB}> </Text>
        <Text style={styles.bodyTabSmallBNB}>Add: CGST</Text>
        <Text style={styles.bodyTabVerySmallBNB}></Text>
        <Text style={styles.bodyTabSmallNBB}>
          @ {((invoice?.cgst / invoice?.preGstTotal) * 100)?.toFixed(2)} %
        </Text>
        <Text style={styles.bodyTabSmallLastNB}>
          {" "}
          {invoice?.cgst?.toFixed(2)}
        </Text>
      </View>
      <View
        style={{
          display: "flex",
          flexDirection: "row",
        }}
      >
        <Text style={styles.bodyTabVerySmallNB}></Text>
        <Text style={styles.bodyTabNB}></Text>
        <Text style={styles.bodyTabVerySmallNB}> </Text>
        <Text style={styles.bodyTabSmallNB}>Add: SGST</Text>
        <Text style={styles.bodyTabVerySmallNB}></Text>
        <Text style={styles.bodyTabSmallR}>
          @ {((invoice?.sgst / invoice?.preGstTotal) * 100)?.toFixed(2)} %
        </Text>
        <Text style={styles.bodyTabSmallLast}>
          {" "}
          {invoice?.sgst?.toFixed(2)}
        </Text>
      </View>
      <View
        style={{
          display: "flex",
          flexDirection: "row",
        }}
      >
        <Text style={styles.VerybodyTaBbNB}></Text>
        <Text style={styles.bodyTabSmallNBBR}></Text>
        <Text style={styles.bodyTabSmallBNB}></Text>
        <Text style={styles.VerybodyTaBbNB}></Text>
        <Text style={styles.VerybodyTaBbNB}></Text>

        <Text style={styles.bodyTaBbNBR}> Grand Total</Text>
        <Text style={styles.bodyTabSmallLast}>
          {" "}
          {invoice?.grandTotal?.toFixed(2)}
        </Text>
      </View>
    </View>
  );
};

export default Table;

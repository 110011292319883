import React, { useState } from "react";
import ClientSearch from "../../components/ClientInternalSearch";
import Layout from "../../components/Layout/Layout";
import Loading from "../../components/Loading";
import Search from "../../components/Search";
import { clientgetCustomerById, getAllCustomers } from "../../utils/api";
import useStore from "../../utils/store";

const Client = () => {
  const [customers, setCustomers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [customer, setCustomer] = useState(null);
  const startDate = useStore((state) => state.startDate);
  const endDate = useStore((state) => state.endDate);
  const [clientData, setClientData] = useState({});
  const [data, setData] = useState({});
  const getAllCustomerHandler = async () => {
    setLoading(true);
    const [success, data] = await getAllCustomers(startDate, endDate);
    if (success) {
      setCustomers(
        data.map((item) => ({
          ...item,
          name: `${item.userName} ${
            item?.group?.name !== undefined ? `(${item?.group?.name})` : ""
          }`,
          curName: item?.name,
        }))
      );
    }
    setLoading(false);
  };

  React.useEffect(() => {
    getAllCustomerHandler();
  }, [startDate, endDate]);

  const getCustomersByIdHandler = async (id, name) => {
    setLoading(true);
    const [success, data] = await clientgetCustomerById(id, startDate, endDate);

    if (success) {
      setCustomer({
        ...data,
        name,
      });
    }
    setLoading(false);
  };
  return (
    <Layout>
      <div className="flex gap-3  flex-col md:flex-row text-[80%]">
        {loading && <Loading />}
        <Search
          data={customers.filter((item) => item?.type === "Client")}
          item={(item) => {
            getCustomersByIdHandler(item._id, item?.name);
            setData({
              cur: item._id,
            });
            setClientData(item);
          }}
          active={data.cur}
        />
        <div className="md:w-[calc(100%-16rem)]">
          {customer && (
            <ClientSearch
              id={data.cur}
              customers={customers}
              client={clientData}
            />
          )}
        </div>
      </div>
    </Layout>
  );
};

export default Client;
